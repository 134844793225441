import React, { useState } from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { Box, TableFooter } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { formatIndianNumber } from "../../_services/formateRevenueData";
import { useDispatch, useSelector } from 'react-redux';

const TableComponent = ({ columns, data, total_count, headFooter, footerHide, hideTotal, widthSet, page, setPage, rowsPerPage, setRowsPerPage }) => {
    const dispatch = useDispatch();
    const [orderBy, setOrderBy] = useState('');
    const [order, setOrder] = useState('asc');
    const location = useLocation();
    const loader = useSelector((state) => state.royaltyDataReducer.loading);
    const skeletonData = [1, 2, 3, 4, 5, 6]
    const isDetailsPage = location.pathname === '/details';
    const handleSort = (property) => (event) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedData = data.sort((a, b) => {
        if (order === 'asc') {
            return a[orderBy] < b[orderBy] ? -1 : 1;
        } else {
            return a[orderBy] > b[orderBy] ? -1 : 1;
        }
    });

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };
    return (
        <Box>
            <TableContainer className="table-container">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead className="sticky-header">
                        <TableRow>
                            {columns.map((column, index) => (
                                (isDetailsPage && index === columns.length - 1 && column.id === 'action') ? null : <TableCell
                                    sx={{ backgroundColor: '#F6F5F5' }}
                                    key={index}
                                    align={column.align}
                                >
                                    <TableSortLabel
                                        // className="suman"
                                        active={orderBy === column.id}
                                        direction={orderBy === column.id ? order : 'asc'}
                                        onClick={handleSort(column.id)}
                                    >
                                        {column.label}
                                    </TableSortLabel>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loader ? (
                            <TableRow>
                                <TableCell colSpan={columns.length}>
                                    {skeletonData.map(d => (<Skeleton animation="wave" height={50} />))}
                                </TableCell>
                            </TableRow>
                        ) : loader == false && data.length == 0 ? (
                            <TableRow>
                                <TableCell className="text-center p-4 text-bold" colSpan={columns.length}>
                                    NO DATA FOUND
                                </TableCell>
                            </TableRow>
                        ) : (
                            sortedData.map((row, index) => (
                                <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                    {columns.map((column, columnIndex) => (
                                        (isDetailsPage && columnIndex === columns.length - 1 && column.id === 'action') ? null : (<TableCell
                                            sx={{
                                                ' &:first-of-type': widthSet ? { width: '50%' } : null
                                            }}
                                            key={column.id}
                                            align={column.align}
                                        >
                                            {column.template ? column.template(row) : !isNaN(row[column.id]) ? formatIndianNumber(row[column.id]) : row[column.id]}
                                        </TableCell>)
                                    ))}
                                </TableRow>
                            ))

                        )}
                    </TableBody>
                    {hideTotal ? null : <TableFooter>
                        <TableRow sx={{ backgroundColor: '#F6F5F5' }}>
                            {headFooter.map((headCell, columnIndex) => (
                                (isDetailsPage && columnIndex === headFooter.length - 1 && headCell.id === 'action') ? null :
                                    <TableCell
                                        className='p-3 fw-bold text-body'
                                        sx={{ fontSize: '14px' }}
                                        key={headCell.id}
                                        align='left'
                                        padding={headCell.disablePadding ? 'none' : 'normal'}
                                    >
                                        {headCell.label}
                                    </TableCell>
                            ))}
                        </TableRow>
                    </TableFooter>}
                </Table>
            </TableContainer>
            {console.debug("aaaaaaaaaa", { isDetailsPage, total_count, data })}
            {footerHide ? null : (
                <TablePagination
                    rowsPerPageOptions={[10, 15, 20]}
                    component="div"
                    count={(!isDetailsPage && total_count) ? total_count : data[0]?.total_count ? data[0]?.total_count : data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </Box>

    );
};

export default TableComponent;
