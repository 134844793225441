import React, { useEffect } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete, Box, FormControl, InputAdornment, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import Chip from '@mui/material/Chip';
import { associatedArtistsRequestHandler } from '../../../_services/associatedArtists/associatedArtist.service';
import { useSelector, useDispatch } from 'react-redux';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../../redux';
import { formSnackbar } from '../../../_constants/snackbar.constant';
import { artistCode } from './artistStaticCodes'
import { useState } from 'react';
import { regexValidator } from '../../../_helpers/reusablefunctions/regexValidator';
import { SearchAllAssociateArtistList } from '../../../_services/AssociateArtistSearch/searchAllAssociateArtist.service';
import SearchIcon from '@mui/icons-material/Search';

const AddAssociatedArtist = ({ handleDrawerClose, getAssociatedArtist, assoArtistRemoveHandler }) => {
  const userData = useSelector(state => state.userData.userData)
  const dispatch = useDispatch();
  const [artistTypes, setArtistTypes] = React.useState([
    { label: 'Primary Artist', code: artistCode.primary_artist },
    { label: 'Singer', code: artistCode.singer },
    { label: 'Feature Artist', code: artistCode.feature_artist },
    { label: 'Lyricist', code: artistCode.lyricist },
    { label: 'Composer', code: artistCode.composer },
    { label: 'Producer', code: artistCode.producer },
    { label: 'Actor', code: artistCode.actor },
    { label: 'Remixer Artist', code: artistCode.remixer_artist }
  ])
  const [primaryArtistList, setPrimaryArtistList] = React.useState([])
  const [singerList, setSingerList] = React.useState([])
  const [featuringArtistList, setfeaturingArtistList] = React.useState([])
  const [composerList, setComposerList] = React.useState([])
  const [lyricistList, setLyricistList] = React.useState([])
  const [remixerArtList, setRemixerArtList] = React.useState([])
  const [producerList, setProducerList] = React.useState([])
  const [actorList, setActorList] = React.useState([])
  const [searchInput, setSearchInput] = useState('');
  const [selectedSearchResult, setSelectedSearchResult] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [errors, setErrors] = React.useState({
    spotify: false,
    spotify_helperTxt: null,
  })
  const [assoArtist, setAssoArtist] = React.useState({
    artist_name: '',
    artist_type: '',
    spotify_id: '',
    instagram_id: '',
    apple_music_id: '',
    name: '',
    creator_gnid: ''
  })

  const assoArtistData = [{ id: 'spotify_id', label: 'Spotify ID', value: assoArtist.spotify_id, inputValue: 'spotify:artist:', helperText: errors.spotify_helperTxt, error:errors.spotify },
  { id: 'instagram_id', label: 'Instagram Handle', value: assoArtist.instagram_id, inputValue: 'instagram:artist:' }, { id: 'apple_music_id', label: 'Apple Music Id', value: assoArtist.apple_music_id, inputValue: 'apple-music:artist:' },
  ]

  const artistTypeChangeHandler = (e) => {
    setAssoArtist({ ...assoArtist, artist_type: e.target.value })
  }
  useEffect(() => {
    const getResult = setTimeout(() => {
      if (searchInput === '') {
        setIsDropdownOpen(false);
        setFilteredOptions([]);
      } else {
        artistSearch(searchInput)
      }
    }, 400

    )
    return () => clearTimeout(getResult)
  }, [searchInput])


  const artistSearch = (searchInput) => {
    if (searchInput && searchInput.length !== '') {
      const postData = {
        search: searchInput,
      };
      //  dispatch(setLoader(true));
      SearchAllAssociateArtistList(postData, 'SEARCH')
        .then(function (response) {
          //  dispatch(setLoader(false));
          if (response.status === 200) {
            setFilteredOptions(response.data.results);
          } else {
            dispatch(setSnackBar(true));
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
            dispatch(setSnackBarVariant('error'));
            setTimeout(() => dispatch(setSnackBar(false)), 3000);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  };

  const artistNameChangeHandler = (e) => {
    if (e.target.name === 'asso-name') {
      setAssoArtist({
        ...assoArtist,
        name: e.target.value.replace(/[^a-zA-Z0-9\s\-._]/g, '')
      });
    }
    else {
      setAssoArtist({
        ...assoArtist,
        artist_name: e.target.value.replace(/[^a-zA-Z0-9\s\-._]/g, '')
      });
    }
  }

  const inputChangeHandler = (event) => {
    const { name, value } = event.target;
    setAssoArtist({ ...assoArtist, [name]: value })
    switch (event.target.name) {
      case 'spotify_id': {
        const isValid = regexValidator(value, 'spotifyUri');
        if (!isValid && value.trim() !== "") {
          setErrors({ ...errors, ['spotify']: true, ['spotify_helperTxt']: formSnackbar.errors.spotifyIdExample });
        }
        else {
          setErrors({ ...errors, ['spotify']: false, ['spotify_helperTxt']: null });
        }
        break;
      }
      default:
        break;
    }
  }

  const addArtistHandler = (event) => {
    if (event !== undefined) {
      const originalDetail = event.nativeEvent.detail;
      if (!originalDetail || originalDetail === 1) {
        let artistTypecd = assoArtist.artist_type;
        handleRequest(artistTypecd);
      }
    }
  };

  //sesstion storage to retain value in the drawer from the client side only, session cleared on the final submission of the song
  const setItemsToSession = (key, value) => {
    window.sessionStorage.setItem(key, value)
  }

  const setItemsToStateFromSession = () => {
    Object.keys(window.sessionStorage).forEach(key => {
      let sessionData = window.sessionStorage.getItem(key)
      if (key === artistCode.primary_artist)
        sessionData && setPrimaryArtistList(JSON.parse(sessionData))
      if (key === artistCode.singer)
        sessionData && setSingerList(JSON.parse(sessionData))
      else if (key === artistCode.feature_artist)
        sessionData && setfeaturingArtistList(JSON.parse(sessionData))
      else if (key === artistCode.lyricist)
        sessionData && setLyricistList(JSON.parse(sessionData))
      else if (key === artistCode.composer)
        sessionData && setComposerList(JSON.parse(sessionData))
      else if (key === artistCode.producer)
        sessionData && setProducerList(JSON.parse(sessionData))
      else if (key === artistCode.actor)
        sessionData && setActorList(JSON.parse(sessionData))
      else if (key === artistCode.remixer_artist)
        sessionData && setRemixerArtList(JSON.parse(sessionData))
    })
  }
  useEffect(() => { setItemsToStateFromSession() }, [])

  const storeCreatedArtistInfo = (artistTypecd, response) => {
    switch (artistTypecd) {
      case artistCode.primary_artist: {
        setPrimaryArtistList([...primaryArtistList, response])
        setItemsToSession(artistCode.primary_artist, JSON.stringify([...primaryArtistList, response]))
        break
      }
      case artistCode.singer: {
        setSingerList([...singerList, response])
        setItemsToSession(artistCode.singer, JSON.stringify([...singerList, response]))
        break
      }
      case artistCode.feature_artist: {
        setfeaturingArtistList([...featuringArtistList, response])
        setItemsToSession(artistCode.feature_artist, JSON.stringify([...featuringArtistList, response]))
        break
      }
      case artistCode.lyricist: {
        setLyricistList([...lyricistList, response])
        setItemsToSession(artistCode.lyricist, JSON.stringify([...lyricistList, response]))
        break
      }
      case artistCode.composer: {
        setComposerList([...composerList, response])
        setItemsToSession(artistCode.composer, JSON.stringify([...composerList, response]))
        break
      }
      case artistCode.producer: {
        setProducerList([...producerList, response])
        setItemsToSession(artistCode.producer, JSON.stringify([...producerList, response]))
        break
      }
      case artistCode.actor: {
        setActorList([...actorList, response])
        setItemsToSession(artistCode.actor, JSON.stringify([...actorList, response]))
        break
      }
      case artistCode.remixer_artist: {
        setRemixerArtList([...remixerArtList, response])
        setItemsToSession(artistCode.remixer_artist, JSON.stringify([...remixerArtList, response]))
        break
      }
      default:
        break;
    }
  }
  const handleRequest = (artistType) => {
    dispatch(setLoader(true))
    let postObj = { artist_name: assoArtist.artist_name, name: assoArtist.name, associated_with_studio: userData && userData.studio_id, spotify: assoArtist.spotify_id, instagram: assoArtist.instagram_id, apple_music_id: assoArtist.apple_music_id, creator_gnid: assoArtist.creator_gnid }
    associatedArtistsRequestHandler(postObj, 'POST')
      .then(response => {
        dispatch(setLoader(false))
        dispatch(setSnackBar(true));
        let resData = response.data
        if (response.status === 200 || response.status === 201) {
          getAssociatedArtist(artistType, [resData], true) //to refresh the list
          let obj = {
            artist_name: '',
            artist_type: '',
            spotify_id: '',
            instagram_id: '',
            apple_music_id: '',
            name: '',
            creator_gnid: ''
          }
          setAssoArtist(obj)
          storeCreatedArtistInfo(artistType, resData)
          dispatch(setSnackBarVariant('success'))
          dispatch(setSnackBarMessage(formSnackbar.associatedArtist.success))
          setTimeout(() => {
            dispatch(setSnackBar(false));
          }, 3000);
          setSearchInput('');
        }
        else {
          if (response.data.data === false) {
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage(formSnackbar.associatedArtist.duplicateArtist))
            setTimeout(() => dispatch(setSnackBar(false)), 5000)
            dispatch(setLoader(false))
          }
          else {
            dispatch(setLoader(false))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage(formSnackbar.serviceRequest.serviceRequestFailure))
            setTimeout(() => dispatch(setSnackBar(false)), 3000)
          }
        }
      })
      .catch(err => {
        dispatch(setLoader(false))
        dispatch(setSnackBar(true));
        setTimeout(() => {
          dispatch(setSnackBar(false));
        }, 3000);
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      })
  }

  //to remove the added artist from the song
  const removeAddedArtistFromSong = (artistType, itemIndex, artistId) => {
    switch (artistType) {
      case artistCode.primary_artist:
        {
          let udpatedList = handleItemRemove(primaryArtistList, itemIndex)
          setPrimaryArtistList(udpatedList)
          setItemsToSession(artistCode.primary_artist, JSON.stringify(udpatedList))
          break;
        }
      case artistCode.singer:
        {
          let udpatedList = handleItemRemove(singerList, itemIndex)
          setSingerList(udpatedList)
          setItemsToSession(artistCode.singer, JSON.stringify(udpatedList))
          break;
        }
      case artistCode.feature_artist:
        {
          let udpatedList = handleItemRemove(featuringArtistList, itemIndex)
          setfeaturingArtistList(udpatedList)
          setItemsToSession(artistCode.feature_artist, JSON.stringify(udpatedList))
          break;
        }
      case artistCode.lyricist:
        {
          let udpatedList = handleItemRemove(lyricistList, itemIndex)
          setLyricistList(udpatedList)
          setItemsToSession(artistCode.lyricist, JSON.stringify(udpatedList))
          break;
        }
      case artistCode.composer:
        {
          let udpatedList = handleItemRemove(composerList, itemIndex)
          setComposerList(udpatedList)
          setItemsToSession(artistCode.composer, JSON.stringify(udpatedList))
          break;
        }
      case artistCode.producer:
        {
          let udpatedList = handleItemRemove(producerList, itemIndex)
          setProducerList(udpatedList)
          setItemsToSession(artistCode.producer, JSON.stringify(udpatedList))
          break;
        }
      case artistCode.actor:
        {
          let udpatedList = handleItemRemove(actorList, itemIndex)
          setActorList(udpatedList)
          setItemsToSession(artistCode.actor, JSON.stringify(udpatedList))
          break;
        }
      case artistCode.remixer_artist:
        {
          let udpatedList = handleItemRemove(remixerArtList, itemIndex)
          setRemixerArtList(udpatedList)
          setItemsToSession(artistCode.remixer_artist, JSON.stringify(udpatedList))
          break;
        }
      default:
        break;
    }
    //to remove artist from the select boxes in the song_cretid step
    assoArtistRemoveHandler(artistType, itemIndex, artistId)
  }

  const handleItemRemove = (list, index) => {
    let newlist = [...list];
    newlist.splice(index, 1)
    return newlist;
  }

  const renderChips = (artistTypeLabel, artistTypecd, list) => {
    return (
      <div>
        <div className='d-flex align-items-center my-3'>
          <span className="fs-6 text-secondary">Added {artistTypeLabel} Artist</span>
          <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
        </div>
        <div>
          {list.map((artist, index) => <Chip key={`artistTypecd_${artist.artist_id}}`} label={artist.artist_name} onDelete={() => removeAddedArtistFromSong(artistTypecd, index, artist.artist_id)} sx={{ margin: '2px 5px' }} />)}
        </div>
      </div>
    )
  }
  const handleArtistSearch = (event, newValue) => {
    if (newValue?.name) {
      setSelectedSearchResult(newValue);
      if (newValue) {
        setAssoArtist({
          ...assoArtist,
          name: `${newValue.name}`,
          artist_name: newValue.artist_name,
          creator_gnid: newValue.creator_gnid
        });
      } else {
        setAssoArtist({
          ...assoArtist,
          name: '',
          artist_name: '',
          creator_gnid: ''
        });
      }
    }
  };

  const handleSearchInputChange = (event, newValue) => {
    if (event) {
      setSearchInput(newValue);
    }
    if (searchInput !== undefined && searchInput !== null) {
      artistSearch(searchInput);
      setSearchInput(newValue);
    }
  };

  const artiestRequestHandler = (data) => {
    return (
      <TextField
        key={data.id}
        variant="outlined"
        label={data.label}
        size="small"
        type="text"
        name={data.id}
        value={data.value}
        onChange={inputChangeHandler}
        fullWidth
        inputProps={{ maxLength: 2000 }}
        sx={{ mb: 4, backgroundColor: 'transparent' }}
        helperText={data.spotify_helperTxt}
        error={data.spotify}
        InputProps={{
          startAdornment: <InputAdornment position="start">{data.inputValue}</InputAdornment>,
        }}
      />
    )
  }

  return (
    <div>
      <div className="px-4 py-3 shadow d-flex justify-content-between align-items-center" style={{ backgroundColor: '#F6F6F6' }}>
        <div>
          <h5> Add New Artist </h5>
        </div>
        <div style={{ position: 'relative', top: '-5px' }}>
          <button title="Close" className="icononly-btn" onClick={handleDrawerClose}>
            <CloseIcon />
          </button>
        </div>
      </div>
      <div className='p-4'>
        <h6>Find Existing Artists</h6>
        <p className='text-secondary' style={{ fontSize: '0.9rem' }}>Use the search bar to discover artists already in our system. If the artist is not found in our system, please manually enter the artist details.</p>
        <div id="form-container">
          <Autocomplete
            id="artist-search"
            size="small"
            options={filteredOptions}
            getOptionLabel={(artist) => `${artist.name} (${artist.artist_name})`}
            fullWidth
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2 } }} {...props}>
                <Box>{option.artist_name}</Box>
                <Box sx={{ fontSize: '0.8rem' }}>{option.name && `(${option.name})`}</Box>
              </Box>
            )}
            autoHighlight
            freeSolo
            inputValue={searchInput !== 'undefined (undefined)' ? searchInput : ''}
            value={selectedSearchResult}
            sx={{ mb: 4, backgroundColor: 'transparent' }}
            onChange={handleArtistSearch}
            open={isDropdownOpen}
            onOpen={() => setIsDropdownOpen(true)}
            onClose={() => setIsDropdownOpen(false)}
            onInputChange={handleSearchInputChange}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Artist"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />


          <h6>Artist Information for Song Release</h6>

          <TextField
            variant="outlined"
            label="Artist Name *"
            size="small"
            type="text"
            name='asso-artist-name'
            value={assoArtist.artist_name}
            onChange={artistNameChangeHandler}
            fullWidth
            inputProps={{ maxLength: 64 }}
            sx={{ mb: 4, backgroundColor: 'transparent' }}
            helperText={assoArtist.artist_name && assoArtist.artist_name.length >= 64 ? 'Maximum character limit is 64' : ''}

          />

          <TextField
            variant="outlined"
            label="Name *"
            size="small"
            type="text"
            name='asso-name'
            value={assoArtist.name}
            onChange={artistNameChangeHandler}
            fullWidth
            inputProps={{ maxLength: 64 }}
            sx={{ mb: 4, backgroundColor: 'transparent' }}
            helperText={assoArtist.name.length >= 64 ? 'Maximum character limit is 64' : ''}
          />
          <FormControl fullWidth size='small'>
            <InputLabel>Artist Type *</InputLabel>
            <Select
              name='artist_type'
              value={assoArtist.artist_type}
              label="Artist Type *"
              onChange={artistTypeChangeHandler}
              sx={{ mb: 4, backgroundColor: 'transparent' }}
              MenuProps={{
                style: {
                  maxHeight: 250,
                },
              }}
            >
              {artistTypes ? artistTypes.map(artist => (
                <MenuItem key={artist.code} value={artist.code}>{artist.label}</MenuItem>
              )) : <MenuItem value='null'></MenuItem>}
            </Select>
          </FormControl>
          {/* {assoArtist.artist_type === artistCode.primary_artist && ( */}
          {assoArtistData.map(d => artiestRequestHandler(d))}
          {/* )} */}
        </div>

        {/* drawer footer actions */}
        <div className='py-4 d-flex justify-content-end'>
          <button
            disabled={!assoArtist.artist_type || !assoArtist.artist_name || !assoArtist.name || errors.spotify}
            onClick={addArtistHandler}
            className='gn-actionbtn'
            style={{ minWidth: '100px' }} >
            Save
          </button>
        </div>

        {/* recent added artist */}
        {primaryArtistList && primaryArtistList.length > 0 && renderChips('primary', artistCode.primary_artist, primaryArtistList)}
        {singerList && singerList.length > 0 && renderChips('singer', artistCode.singer, singerList)}
        {featuringArtistList && featuringArtistList.length > 0 && renderChips('feature', artistCode.feature_artist, featuringArtistList)}
        {lyricistList && lyricistList.length > 0 && renderChips('lyricist', artistCode.lyricist, lyricistList)}
        {composerList && composerList.length > 0 && renderChips('composer', artistCode.composer, composerList)}
        {producerList && producerList.length > 0 && renderChips('producer', artistCode.producer, producerList)}
        {actorList && actorList.length > 0 && renderChips('actor', artistCode.actor, actorList)}
        {remixerArtList && remixerArtList.length > 0 && renderChips('remixer', artistCode.remixer_artist, remixerArtList)}

      </div>

    </div>
  )
}

export default AddAssociatedArtist