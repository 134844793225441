import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery'
import { getDateAsPerFormat, getTime } from '../../../_helpers/reusablefunctions/dateSetter';
import AudioPlayer from '../../../components/reusableComponents/customAudioPlayer';


const FinalReview = ({ data, songPoster, imgPlaceHolder, genre, audioFile, language, subGenreList, moodList, singerList,
  primaryArtList, featureArtList, composerList, producerList, remixerArtList, actorList, lyricistList, recordLabelList, copyrights_p,
  copyrights_c, copyrightc_name, copyrightp_name, copyrightList, titleVersionList }) => {
  const smScreen = useMediaQuery('(max-width:768px)');

  const [showFullLyrics, setShowFullLyrics] = useState(false);
  // This function is just handle the scenario for record label;
  const handleRecordLabelDisplay = (recordLabel) => {
    if (Array.isArray(recordLabel))
      return recordLabelList && recordLabelList.length > 0 ? recordLabelList.find(item => item.label_id === data.record_labels[0]).label_name : ''
    else
      if (recordLabel != null && recordLabel != undefined && recordLabel != "")
        return recordLabelList && recordLabelList.length > 0 ? recordLabelList.find(item => item.label_id === data.record_labels).label_name : ''
      else
        return ""
  }

  const toggleReadMore = (e) => {
    e.preventDefault();
    setShowFullLyrics(!showFullLyrics);
  };

  const truncatedLyrics = data && data.song_lyrics ? data.song_lyrics.substring(0, 500) : '';
  const displayedLyrics = showFullLyrics ? data?.song_lyrics : truncatedLyrics;

  return (
    <>
      <h4 className="cardheading">Final Review</h4>
      <div className='pb-5'>
        {/* <div className='mt-2' id="song-poster-cnt"> */}
        {/* <h5>Cover</h5> */}
        {/* <img src={songPoster ? songPoster : imgPlaceHolder} alt="Upload poster" className="songPoster" /> */}
        {/* </div> */}
        <div className='mt-3'>
          {smScreen ? <AudioPlayer type='small' audioSrc={audioFile} /> : <AudioPlayer type='large' audioSrc={audioFile} />}
        </div>
        <div className={`d-flex ${smScreen ? 'flex-wrap align-items-center flex-column' : 'justify-content-between'}`}>

          <div className={`flex-grow-1 ${smScreen ? 'py-3 w-100' : 'px-2'}`}>
            <div className='d-flex align-items-center'>
              <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
            </div>
            <span className="fs-6 fw-bold">Basic Information</span>

            <div className='row mt-3'>
              <div className='col-md-4' style={{ paddingRight: '0.7rem' }}>
                <div className="value-pair fs-09"><strong>Song Name: </strong><span className='text-secondary'>{data.song_name}</span></div>
                {/* <div className="value-pair fs-09"><strong>Content Type: </strong><span className='text-secondary'>{contentTypelist.find(item => item.id.toString() === data.content_types.toString()).content_type_label}</span></div> */}
                <div className="value-pair fs-09"><strong>Genre: </strong><span className='text-secondary'>{genre.find(item => item.id === data.genre).genre}</span></div>
                <div className="value-pair fs-09"><strong>Instrumental: </strong><span className='text-secondary'>{data.instrumental === 'False' || data.instrumental === false ? 'No' : 'Yes'}</span></div>
              </div>
              <div className='col-md-4' >
                {/* <div className="value-pair fs-09"><strong>File/Album: </strong><span className='truncate text-secondary'>{data.album}</span></div> */}
                <div className="value-pair fs-09"><strong>Title Version: </strong><span className='text-secondary'>{titleVersionList?.find(item => item.id === data?.title_version)?.title_version}</span></div>
                <div className="value-pair fs-09"><strong>Sub-Genre: </strong><span className='text-secondary'>{data?.sub_genre ? subGenreList.find(item => item.id === data.sub_genre).sub_genre : '--'}</span></div>
              </div>
              <div className='col-md-4' >
                <div className="value-pair fs-09"><strong>Explicit Content: </strong><span className='text-secondary'>{data.explicit_content === 'False' || data.explicit_content === false ? 'No' : 'Yes'}</span></div>
                <div className="value-pair fs-09"><strong>Mood: </strong><span className='text-secondary'>{data?.mood ? moodList.find(item => item.id === data.mood).mood : '--'}</span></div>
                <div className="value-pair fs-09"><strong>Language: </strong><span>{language && language.length > 0 && language.find(item => item.id === data.language).language}</span></div>

              </div>
              <div className='col-md-12'>
                <div className="value-pair fs-09"><strong>Song Description: </strong><span>{data.song_description}</span></div>
              </div>
              <div className='col-md-12'>
                <div className="value-pair fs-09"><strong>Song Lyrics: </strong>   <span className={showFullLyrics ? 'full-text' : 'truncated-text'}>
                  {displayedLyrics}
                </span>
                  {data.song_lyrics && data.song_lyrics.length > 500 && (
                    <span className="active p-2 fw-bold cursorPointer text-decoration-underline" onClick={toggleReadMore}>
                      {showFullLyrics ? 'Show Less' : 'Read More'}
                    </span>
                  )}</div>
              </div>
            </div>
          </div>

        </div>
        {/* row 2 */}

        {/* row 3*/}
        <div className={`d-flex ${smScreen ? 'flex-wrap align-items-center flex-column' : 'justify-content-between'}`}>
          <div className={`flex-grow-1 ${smScreen ? 'py-3 w-100' : 'px-2'}`}>
            <div className='d-flex align-items-center'>
              <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
            </div>
            <span className="fs-6 fw-bold">Song Credit</span>
            {/* row 3*/}
            <div className='row mt-3'>
              <div className='col-md-4'>
                <div className="value-pair fs-09"><strong>Primary Artist: </strong><span>{primaryArtList && primaryArtList.map(artist => artist.artist_name).join(', ')}</span></div>
                <div className="value-pair fs-09"><strong>Lyricist: </strong><span>{lyricistList && lyricistList.map(artist => artist.artist_name).join(', ')}</span></div>
                <div className="value-pair fs-09"><strong>Actor: </strong><span>{actorList && actorList.map(artist => artist.artist_name).join(', ')}</span></div>

              </div>
              <div className='col-md-4'>
                <div className="value-pair fs-09"><strong>Featuring Artist: </strong><span>{featureArtList && featureArtList.map(artist => artist.artist_name).join(', ')}</span></div>
                <div className="value-pair fs-09"><strong>Producer: </strong><span>{producerList && producerList.map(artist => artist.artist_name).join(', ')}</span></div>
                <div className="value-pair fs-09"><strong>Remixer: </strong><span>{remixerArtList && remixerArtList.map(artist => artist.artist_name).join(', ')}</span></div>
              </div>
              <div className='col-md-4'>
                <div className="value-pair fs-09"><strong>Singer: </strong><span>{singerList && singerList.map(artist => artist.artist_name).join(', ')}</span></div>
                <div className="value-pair fs-09"><strong>Composer: </strong><span>{composerList && composerList.map(artist => artist.artist_name).join(', ')}</span></div>
              </div>

            </div>
          </div>
        </div>

        {/* row 4*/}
        <div className={`d-flex ${smScreen ? 'flex-wrap align-items-center flex-column' : 'justify-content-between'}`}>

          <div className={`flex-grow-1 ${smScreen ? 'py-3 w-100' : 'px-2'}`}>
            <div className='d-flex align-items-center'>
              <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
            </div>
            <span className="fs-6 fw-bold">Release Information</span>
            {/* row 4*/}
            <div className='row mt-3'>
              <div className='col-md-6'>
                <div className="value-pair fs-09"><strong>Has it been previously released?: </strong><span>{data.release_history === 'False' || data.release_history === false ? 'No' : 'Yes'}</span></div>
                <div className="value-pair fs-09"><strong>CallerTune Preview/Clip Start Time: </strong><span>{data.crbt_cuts_duration_for_clip1 ? getTime(data.crbt_cuts_duration_for_clip1) : ''}</span></div>
                <div className="value-pair fs-09"><strong>What’s the origin of the track? : </strong><span>{data.copyright_type === 'False' || data.copyright_type === false ? 'Public Domain' : 'Original Song'}</span></div>
                <div className="value-pair fs-09"><strong>© Copyright Year:</strong><span>  {copyrightList &&
                  copyrightList.map(copyright => {
                    if (copyright.id === copyrights_c) {
                      return copyright.copyright_year + ' Year';
                    }
                    return null; // or an alternative value if there is no match
                  })}</span></div>
                <div className="value-pair fs-09"><strong>© Copyright: </strong><span>{copyrightc_name}</span></div>
                {/* <div className="value-pair fs-09"><strong>Release Time: </strong><span>{data.release_time ? getTime(data.release_time) : ''}</span></div> */}
                {(data.release_history === 'True' || data.release_history === true) && <div className="value-pair fs-09"><strong>UPC Code: </strong><span className='text-secondary'>{data.upc_code}</span></div>}
              </div>
              <div className='col-md-6'>
                {(data.release_history === 'True' || data.release_history === true) && <div className="value-pair fs-09"><strong>Previous Release Date: </strong><span>{data.previous_release_date == (null || '') ? '--' : getDateAsPerFormat(data.previous_release_date, "DD Mon, YYYY")}</span></div>}
                {/* <div className="value-pair fs-09"><strong>CallerTune Preview-2/Clip Start Time: </strong><span>{data.crbt_cuts_duration_for_clip2 ? getTime(data.crbt_cuts_duration_for_clip2) : ''}</span></div> */}
                <div className="value-pair fs-09"><strong>Ⓟ Publishing Year: </strong><span>{copyrightList &&
                  copyrightList.map(copyright => {
                    if (copyright.id === copyrights_p) {
                      return copyright.copyright_year;
                    }
                    return null; // or an alternative value if there is no match
                  })}</span></div>
                <div className="value-pair fs-09"><strong>Ⓟ Copyright: </strong><span>{copyrightp_name}</span></div>
                {/* <div className="value-pair fs-09"><strong>Release Date: </strong><span>{getDateAsPerFormat(data.release_date, "DD Mon, YYYY")}</span></div> */}
                <div className="value-pair fs-09"><strong>ISRC Code: </strong><span className='text-secondary'>{data.isrc_code}</span></div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default FinalReview