import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Radio, Select, TextField, Tooltip } from '@mui/material';
import ClearSelectionIcon from '../songList/helperComponent/ClearSelectionIcon';
const GenralInformation = ({ data, handleInputChange, errors, genre, subGenreList, language, titleVersionList, handleSubmit, releaseStatusReview, releaseStatusReleased,
    albumDisabledStatus, releaseDataBkp, handleBack, setActive, active}) => {
    const [hoveredSelect, setHoveredSelect] = useState(null);

    const handleClearSelection = (name) => {
        handleInputChange({
            target: {
                name: name,
                value: '',
            },
        });
    };

    const handleMouseEnter = (name) => {
        setHoveredSelect(name);
    };

    const handleMouseLeave = () => {
        setHoveredSelect('');
    };


    return (
        <>
            <div>
                <p className='cardheading'>Add Basic Information</p>

                <div className='row'>
                <div className="form-group col-md-6 mt-4">
                        <FormControl
                            name='album_name'
                            noValidate
                            autoComplete="off"
                            disabled={albumDisabledStatus}
                            fullWidth>
                            <TextField
                                size="small"
                                value={data.album_name?data.album_name:""}
                                onChange={handleInputChange}
                                error={errors.album_name}
                                disabled={albumDisabledStatus}
                                inputProps={{ maxLength: 255 }}
                                helpertext={errors.album_name ? errors.album_name_txt:""}
                                name="album_name"
                                label="Release Name*" variant="outlined" className="w-100 rounded "
                            />
                        </FormControl>
                    </div> 
                    <div className='col-md-6 mt-4'>
                        <FormControl fullWidth disabled={albumDisabledStatus} name="data.genre" size="small" >
                            <InputLabel>Genre *</InputLabel>
                            <Select
                                value={data.genre ? data.genre : ''}
                                name="genre"
                                label="Genre*"
                                onChange={handleInputChange}
                                disabled={albumDisabledStatus}
                                onMouseEnter={() => handleMouseEnter('genre')}
                                onMouseLeave={handleMouseLeave}
                                endAdornment={
                                    hoveredSelect === 'genre' &&
                                        !(data.song_status === "In Review" || data.song_status === "Released") ? (
                                        <ClearSelectionIcon name={'genre'} onClear={handleClearSelection} />
                                    ) : null
                                }
                                MenuProps={{
                                    style: {
                                        maxHeight: 250,
                                    },
                                }}
                            >
                                {genre ? genre.map(item => (
                                    <MenuItem key={item.id} value={item.id}>{item.genre}</MenuItem>
                                )) : <MenuItem value='null'></MenuItem>}
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className='row'>
                <div className="form-group col-md-6 mt-4">
                    <FormControl fullWidth disabled={albumDisabledStatus} name="data.genre" size="small" >
                        <InputLabel>Sub Genre</InputLabel>
                        <Select
                            value={data.sub_genre ?data.sub_genre :""}
                            name="sub_genre"
                            label="Sub Genre"
                            onChange={handleInputChange}
                            disabled={albumDisabledStatus}
                            onMouseEnter={() => handleMouseEnter('sub_genre')}
                            onMouseLeave={handleMouseLeave}
                            endAdornment={hoveredSelect === 'sub_genre' && !(data.song_status === "In Review" || data.song_status === "Released") ? (
                                <ClearSelectionIcon name={'sub_genre'} onClear={handleClearSelection} />
                            ) : null}
                            MenuProps={{
                                style: {
                                    maxHeight: 250,
                                },
                            }}
                            error={errors.sub_genre}
                        // helperText={errors?.sub_genre_helperTxt||""}
                        >
                            {subGenreList ? subGenreList.map(item => (
                                <MenuItem key={item.id} value={item.id}>{item.sub_genre}</MenuItem>
                            )) : <MenuItem value='null'></MenuItem>}
                        </Select>
                    </FormControl>
                </div>
                <div className='col-md-6 mt-4'>
                        <FormControl fullWidth disabled={albumDisabledStatus} name="title_version" size="small" >
                            <InputLabel>Title Version(optional)</InputLabel>
                            <Select
                                value={data.title_version ? data.title_version:""}
                                name="title_version"
                                label="Title Version(optional)"
                                disabled={albumDisabledStatus}
                                onChange={handleInputChange}
                                onMouseEnter={() => handleMouseEnter('title_version')}
                                onMouseLeave={handleMouseLeave}
                                endAdornment={hoveredSelect === 'title_version' && !(data.song_status === "In Review" || data.song_status === "Released") ? (
                                    <ClearSelectionIcon name={'title_version'} onClear={handleClearSelection} />
                                ) : null}
                                MenuProps={{
                                    style: {
                                        maxHeight: 250,
                                    },
                                }}
                            >
                                {titleVersionList && titleVersionList.map((item, index) => (
                                    <MenuItem
                                        value={item.id}
                                        key={item.id}
                                    >
                                        {item.title_version}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                  </div>
                <div className="form-group col-md-12 mt-3 mb-3">
                    <FormControl fullWidth disabled={albumDisabledStatus} name="data.language" size="small" >
                        <InputLabel>Language *</InputLabel>
                        <Select
                            value={data.language ? data.language:''}
                            name="language"
                            label="Language"
                            onChange={handleInputChange}
                            disabled={albumDisabledStatus}
                            onMouseEnter={() => handleMouseEnter('language')}
                            onMouseLeave={handleMouseLeave}
                            endAdornment={hoveredSelect === 'language' && !(data.song_status === "In Review" || data.song_status === "Released") ? (
                                <ClearSelectionIcon name={'language'} onClear={handleClearSelection} />
                            ) : null}
                            MenuProps={{
                                style: {
                                    maxHeight: 250,
                                },
                            }}
                        >
                            {language ? language.map(item => (
                                <MenuItem key={item.id} value={item.id}>{item.language}</MenuItem>
                            )) : <MenuItem value='null'></MenuItem>}
                        </Select>
                    </FormControl>
                </div>
                <div className='mt-3'>
                    <FormControl
                        name='album_description'
                        noValidate
                        autoComplete="off"
                        disabled={albumDisabledStatus}
                        fullWidth
                    >
                        <TextField
                            variant="outlined"
                            label="Release Description(Optional)"
                            name='album_description'
                            value={data.album_description}
                            disabled={albumDisabledStatus}
                            onChange={handleInputChange}
                            size="small"
                            type="text"
                            multiline={true}
                            rows={4}
                            error={errors.album_description}
                            helperText={errors?.album_description_helperTxt || ""}
                        />
                    </FormControl>
                </div>

                <div className="form-group col-md-12 mt-5 d-flex justify-content-between " >
                <button
                //   disabled={prevBtnDisable}
                  onClick={handleBack}
                  style={{ mr: 1, color:'#E41116' }}
                  className='fs14 border-0 bg-body fw-500'
                 
                >
                  PREVIOUS
                </button>
                    <Tooltip title={data.song_status == "In Review" ? releaseStatusReview : data.song_status == "Released" ? releaseStatusReleased : ""} arrow>
                    <span >
                        {/* <button disabled={
                            albumDisabledStatus ? true :
                                (data.album_name === '' || data.album_name === null ||
                                    data.genre === '' || data.genre === null ||
                                    data.sub_genre === '' || data.sub_genre === null ||
                                    data.language === '' || data.language === null) ? true :
                                    JSON.stringify(data) === JSON.stringify(releaseDataBkp) ? true :
                                        false
                    } onClick={() => handleSubmit()
                    } className='nxt_actionbutton mx-3 py-1 px-4 fs14' >NEXT</button> */}
          <button onClick={() => (JSON.stringify(data) === JSON.stringify(releaseDataBkp)) ? setActive(active+1) :handleSubmit() } className='nxt_actionbutton mx-3 py-1 px-4 fs14' >{JSON.stringify(data) === JSON.stringify(releaseDataBkp)? "NEXT":"SAVE"}</button>
                        </span>
                    </Tooltip>
                </div>
            </div>
        </>
    )
}

export default GenralInformation