import { Autocomplete, Avatar, Box, Button, Card, CircularProgress, Fade, FormControl, IconButton, Modal, Skeleton, Slider, Stack, TextField, Tooltip, Typography } from '@mui/material'
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import React from 'react'
import { formSnackbar } from '../../_constants/snackbar.constant';
import { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserDetail, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import SnackbarContainer from '../Snackbar';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import RefreshIcon from '@mui/icons-material/Refresh';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import '../../style/style.css'
import FileUploader from '../../components/reusableComponents/FileUploader';
import { uploadSongTrack } from '../../_services/submitAsong/uploadSongTrack.service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import AudioPlayer from '../../components/reusableComponents/customAudioPlayer';
import { CreateSongThroughTrack } from '../../_services/submitAsong/createSongbyUploadTrack.service';
import { getSongListOfUser } from '../../_services/album/getSongListofUser';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { removeSongFromAlbum } from '../../_services/album/removeSongFromAlbum';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DownloadIcon from '@mui/icons-material/Download';
import LinearProgress from '@mui/material/LinearProgress';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import ApiService from '../../_services/ApiService';
export const StreamingPlatformContext = React.createContext();

function UploadAlbumFile({ data, albumData, selectedFile, setSelectedFile, handleDrop, handleFileChange, handleDragOver, handleSubmit, type, setSongList, releaseStatusReview, releaseStatusReleased, albumDisabledStatus, releaseDataBkp, setActive, active }) {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userData.userData);
  const history = useNavigate()
  const [reuploadAudio, setReuploadAudio] = React.useState(false)
  const fileInputRef = React.useRef(null);
  const AudiofileInputRef = React.useRef(null);
  const { albumId } = useParams();
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [albumSongLibrary, setAlbumSongLibrary] = useState([])
  const [file, setFile] = useState(null);
  const [uploadedSize, setUploadedSize] = useState(null)
  const [audioFile, setAudioFile] = useState(null);
  const [audioId, setAudioId] = useState(null)
  const [disabledAudioAction, setDisabledAudioAction] = useState(false)
  const [isFileUploaded, setIsFileUploaded] = useState(false)
  const [fileDetails, setFileDetails] = React.useState(file ? file : { name: '', size: '' })
  const [loadervalue, setLoaderValue] = useState(false)
  const [downloadProgress, setDownloadProgress] = useState(0)
  const [downloadId, setDownloadId] = useState(null)

  const smScreen = useMediaQuery('(max-width:600px)');

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', width: '30%' }}>
        <Box sx={{ minWidth: 130 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Download progress
          </Typography>
        </Box>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {props?.value + '%'}
          </Typography>
        </Box>
      </Box>
    );
  }

  useEffect(() => {
    if (userData.studio_id) {
      getAlbumData()
    }

  }, [userData.studio_id])

  const getAlbumData = async () => {
    setLoaderValue(true)
    try {
      // const response = await getSingleAlbum({ 'userId': userData && userData.studio_id }, { "album_id": albumId })
      const albumSongList = await getSongListOfUser({ 'userId': userData && userData.studio_id }, { "album_id": albumId })
      if (albumSongList) {
        // setAlbumData(response.data[0]) 
        // sort based on re-ordered value
        let arrayData = albumSongList.data[0].song;
        const data = arrayData.sort(function (a, b) {
          return parseInt(a.order) - parseInt(b.order);
        });
        setLoaderValue(false)
        setAlbumSongLibrary(data)
        setSongList(data)
      }
    }
    catch (error) {
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
      setTimeout(function () {
        dispatch(setSnackBar(false))
      }, 2000)
    }
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event, data) => {
    if (data) {
      setAudioId(data.song.song_id)

    }
    setAnchorEl(event.currentTarget);
  };

  const handleActionClose = () => {
    setAnchorEl(null);
  };


  const removeSongFromPlayList = () => {

    removeSongFromAlbum(albumId, audioId)
      .then(function (response) {
        if (response.status === 200 || response.status === 201) {
          getAlbumData(albumSongLibrary)
          dispatch(setSnackBar(true))
          setAudioId(null)
          handleActionClose()
          dispatch(setSnackBarVariant('success'))
          dispatch(setSnackBarMessage(formSnackbar.album.songRemove))
          setTimeout(function () {
            dispatch(setSnackBar(false))
          }, 2000)
        }
      })
      .catch(function (error) {
        dispatch(setSnackBar(true))
        dispatch(setSnackBarVariant('error'))
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
        setTimeout(function () {
          dispatch(setSnackBar(false))
        }, 2000)
      })
  }

  const AddSongMeta = (songId) => {
    history('/addreleasesong/' + songId + "/" + albumId)
  }

  const ViewSongMeta = () => {
    history('/releases/' + audioId + "/" + albumId)
  }

  const handleFileInputChange = (event, id) => {
    const selectedFile = event.target.files[0];

    // Check file format
    const allowedFormats = ['wav'];
    const fileFormat = selectedFile.name.split('.').pop().toLowerCase();
    if (!allowedFormats.includes(fileFormat)) {
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage(formSnackbar.errors.fileTypeUploadError))
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return;
    }

    // Check file size
    const maxSizeInBytes = 250 * 1024 * 1024; // 250MB
    if (selectedFile.size > maxSizeInBytes) {
      dispatch(setSnackBar(true))
      dispatch(setSnackBarVariant('error'))
      dispatch(setSnackBarMessage(formSnackbar.errors.largeFileLimitError))
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return;
    }
    setFile(selectedFile);
    const formData = new FormData();
    const replaceFile = {
      song_file: selectedFile,
      album: albumId
    }
    const addAudioFile = {
      song_file: selectedFile,
      album: albumId,
      publisher_id: userData.studio_id,
      publisher_type: 'studio'
    }
    if ((audioId != undefined || audioId != null) && id != undefined) {
      setReuploadAudio(true)
      for (const key in replaceFile) {
        if (replaceFile.hasOwnProperty(key) && replaceFile[key] !== null) {

          formData.append(key, replaceFile[key]);
        }
      }
    } else {
      setReuploadAudio(false)
      for (const key in addAudioFile) {
        if (addAudioFile.hasOwnProperty(key) && addAudioFile[key] !== null) {

          formData.append(key, addAudioFile[key]);
        }
      }
    }


    if ((audioId != undefined || audioId != null) && id != undefined) {
      handleActionClose()
      setDisabledAudioAction(true)
      uploadSongTrack(formData, id, (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        const uploadedSizeInBytes = progressEvent.loaded;
        setUploadedSize(uploadedSizeInBytes)
        setUploadPercentage(percentCompleted);
      })
        .then((response) => {
          const songData = response.data;
          // setSongDataBkp(songData)
          // Update the state while retaining the previous cover_image key
          setAudioFile(process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + response.data.song_file);
          setIsFileUploaded(true)
          setReuploadAudio(false)
          setAudioId(null)
          // setSpecificSongId()
          setDisabledAudioAction(false)
          getAlbumData()
          // }
          // catch (err) {
          //   alert('Something went wrong. Please try again later')
          // }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setDisabledAudioAction(true)
      CreateSongThroughTrack(formData, (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        const uploadedSizeInBytes = progressEvent.loaded;
        setUploadedSize(uploadedSizeInBytes)
        setUploadPercentage(percentCompleted);
      })
        .then((response) => {

          const songData = response.data;
          // setSongDataBkp(songData)
          // Update the state while retaining the previous cover_image key
          setAudioFile(process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + response.data.song_file);
          setIsFileUploaded(true)
          setDisabledAudioAction(false)
          // setSpecificSongId()
          getAlbumData()
          // }
          // catch (err) {
          //   alert('Something went wrong. Please try again later')
          // }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  // ----------------- Download song audio -----------
  const downloadAudio = async (audioSrc) => {
    try {
      let songLink = `${process.env.REACT_APP_BASEURL}song${audioSrc?.song_file}`;

      const response = await fetch(songLink);
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

      const contentLength = response.headers.get("content-length");
      if (!contentLength) throw new Error("Cannot determine file size");

      const totalSize = parseInt(contentLength, 10);
      let receivedSize = 0;

      const reader = response.body.getReader();
      const chunks = [];

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chunks.push(value);
        receivedSize += value.length;

        // Calculate download progress
        const progress = ((receivedSize / totalSize) * 100).toFixed(2);
        setDownloadProgress(progress == 100.00 ? 0 : progress)
        setDownloadId(audioId)
      }

      // Create Blob from chunks
      const blob = new Blob(chunks);
      const blobUrl = window.URL.createObjectURL(blob);

      // Trigger download
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${audioSrc?.song_name || "Audio"}.wav`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error("Error downloading audio:", error);
    }
  };



  // ----------------Sequence change ----------------
  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const { source, destination } = result;
    const reorderedCards = [...albumSongLibrary];
    const [movedCard] = reorderedCards.splice(source.index, 1);
    reorderedCards.splice(destination.index, 0, movedCard);
    setAlbumSongLibrary(reorderedCards)
    const songIds = reorderedCards.map(item => item.song?.song_id)
    const dataObj = { song_ids: songIds?.join(',') }
    ApiService.put('album_song_sequence', albumId + '/', dataObj, null)
      .then(function (response) {
        //do nothing
      })
      .catch(function (error) {
        console.error(error)
      })
  }

  return (
    <Box>
      <Box className='row'>
        <Box className='d-lg-flex d-md-flex d-xl-flex'>
          {albumData === undefined || albumData?.cover_image === null && albumData?.cover_image == undefined && selectedFile == null ? (
            <Box
              className="w-50"
              onDrop={albumDisabledStatus ? null : handleDrop}
              onDragOver={albumDisabledStatus ? null : handleDragOver}
            >
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none', }}
                disabled={albumDisabledStatus}
                ref={fileInputRef}
              />
              <Box onClick={() => fileInputRef.current.click()} className="audio-uploader-album custom-height ">
                <p className='text-primary'><UploadFileIcon style={{ height: '1.5rem', width: '1.5rem' }} /></p>
                <Box className=''>
                  <Box>
                    <span className='text-primary fs-14'>Click to upload  *</span>
                  </Box>
                  <Box className='fs-14'>
                    <span>or drag and drop *</span>
                  </Box>
                </Box>
                <p className='text-secondary' style={{ fontSize: '0.8rem' }}>JPG, JPEG, PNG (max. 10MB)</p>
              </Box>
            </Box>) :
            selectedFile && (
              <Box>
                <Box className=" mb-3" style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                  <img src={type != 'edit' ? URL.createObjectURL(selectedFile) : process.env.REACT_APP_SONGAPIURL_MEDIA + '/media/' + selectedFile} alt="Selected" style={{ borderRadius: "5px", width: '14rem', height: '14rem' }} />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none', }}
                    disabled={albumDisabledStatus}
                    ref={fileInputRef}
                  />
                  {data.song_status == "In Review" || data.song_status == "Released" ? "" : <Box onClick={() => fileInputRef.current.click()} >
                    <Tooltip title={data.song_status == "In Review" ? releaseStatusReview : data.song_status == "Released" ? releaseStatusReleased : "Update cover image"} arrow>
                      <span className=''>
                        <button style={{ position: 'absolute', top: '3px', right: '3px' }} className='gn-btn-icononly'>
                          <PhotoCameraIcon style={{ background: "white", borderRadius: "10px", }} />
                        </button></span>
                    </Tooltip>
                  </Box>}
                </Box>
              </Box>

            )

          }
          <Box className='mx-4 text-secondary fs14'>
            <span>
              Please note that the cover art must adhere to the following requirements:
            </span>
            <ul>
              <li><span>File Format: JPG or PNG</span></li>
              <li><span>Image Dimensions: 3000x3000 pixels not more than 10MB</span></li>
              <li><span>Aspect Ratio: Square</span></li>
            </ul>
            <span>It can not contain:</span>
            <ul>
              <li><span>Social media logos or handles</span></li>
              <li><span>Brand logos</span></li>
              <li><span>Any text except for artist names and/or the name of the release</span></li>
            </ul>
            <span>
              If it contains any of the above,your release will be rejected(these rules by set by the stores and we have to follow them).
            </span>
          </Box>
        </Box>
        <Box className='border BoxiderColor mt-4 mb-3 pt2'></Box>
        <Box className='mt-3'>
          <Box>
            <Box>
              <Box className="position-relative">
                <Stack
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginTop: "0.5rem",
                    border: "2px dashed rgba(0, 0, 0, 0.12)",
                    borderRadius: "12px",
                    backgroundColor: "rgba(201, 201, 201, 0.1)",
                    ":hover": { backgroundColor: albumDisabledStatus == true || disabledAudioAction == true ? "#DBEAFE" : "", borderColor: albumDisabledStatus == true || disabledAudioAction == true ? 'var(--color-mui-primary-blue)' : "" },
                  }}
                >
                  <Box className="upload-file-icon" sx={{ p: "1rem 0rem" }}>
                    <Avatar style={{ height: '2.5rem', width: '2.5rem', backgroundColor: '#1976D21F' }}>
                      <UploadFileIcon style={{ color: albumDisabledStatus == true || disabledAudioAction == true ? "gainsboro" : "" }} color="primary" />
                    </Avatar>
                  </Box>
                  <Box className="upload-btn-wrapper text-center">
                    <span>
                      <Link className="btn" style={{ color: albumDisabledStatus == true || disabledAudioAction == true ? "gainsboro" : "", textDecoration: "underline", cursor: "pointer" }}>
                        Click to upload *
                      </Link>
                      <input
                        key={0}
                        type="file"
                        name="myfile"
                        multiple
                        accept=".wav"
                        disabled={disabledAudioAction == true || albumDisabledStatus == true ? true : false}
                        onChange={(e) => handleFileInputChange(e, undefined)}
                        ref={AudiofileInputRef}
                        className='fileDragDrop'
                      />

                    </span>
                    <span style={{ color: albumDisabledStatus || disabledAudioAction ? "gainsboro" : "" }} className='d-block'>or Drag and drop audio file</span>
                    <span style={{ color: albumDisabledStatus || disabledAudioAction ? "gainsboro" : "" }} className='d-block'>to add song meta</span>

                  </Box>
                  <Box mb='1rem' mt='0.5rem'>
                    <Typography variant="caption" color={albumDisabledStatus || disabledAudioAction ? "gainsboro" : "text.secondary"} display="block" gutterBottom>Use (.wav) file of max 250MB</Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>
            {file && uploadPercentage !== 100 && reuploadAudio == false ? <FileUploader progressCount={uploadPercentage} fileSize={file ? file.size : fileDetails.size} fileName={file ? file.name : fileDetails.name} uploadProgressBytes={uploadedSize}
              isFileUploaded={isFileUploaded} /> : ""}
            {!selectedFile && fileDetails.name != '' && <Box className="mt-2"><strong>File Name : </strong><span className="text-truncate" title={fileDetails.name}>{fileDetails.name}</span></Box>}

            <Box style={{ position: 'relative' }}>
              <Box className="tempSection">{uploadPercentage === 100 && isFileUploaded === false && <span><CircularProgress /></span>}</Box>
            </Box>

          </Box>


        </Box>

        {/* --------------- Song drag and drop code start ---------------- */}
        {albumSongLibrary.length > 0 || loadervalue == false ? <DragDropContext onDragEnd={handleOnDragEnd}>
          <Droppable droppableId="album-song-library">
            {(provided) => (
              <Box {...provided.droppableProps} ref={provided.innerRef}>
                {albumSongLibrary.map((a, index) => (
                  <Draggable key={a.song.song_id} draggableId={a.song.song_id.toString()} index={index}>
                    {(provided) => (
                      <Box className='mt-4' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={{ userSelect: 'none', ...provided.draggableProps.style }}>
                        <Card sx={{ display: 'flex', alignItems: 'center', gap: 3, padding: '10px 0px' }}>
                          <Box className="drag-hanlder" sx={{ cursor: 'grab' }}>
                            <DragIndicatorIcon />
                          </Box>
                          <Box sx={{ width: '100%' }}>
                            <Box display={'flex'} gap={3} justifyContent={'space-between'}>
                              <h6>{a.song.song_name == null ? "Audio " + (index + 1) : a.song.song_name}</h6>
                              {downloadProgress > 0 && downloadProgress != "100.00" && a.song.song_id == downloadId ?
                                <LinearProgressWithLabel value={downloadProgress} /> : null}
                            </Box>
                            <Box className='d-flex align-items-center mt-2' style={{ opacity: a.song.song_file ? '1' : '0.5', pointerEvents: a.song.song_file ? 'auto' : 'none' }}>
                              {a.song.song_id == audioId && file && uploadPercentage !== 100 && reuploadAudio == true ?
                                <Box className='flex-grow-1'>
                                  <FileUploader progressCount={uploadPercentage} fileSize={file ? file.size : fileDetails.size} fileName={selectedFile ? selectedFile.name : fileDetails.name} uploadProgressBytes={uploadedSize}
                                    isFileUploaded={isFileUploaded} /> </Box> :
                                <Box className='flex-grow-1'>
                                  {smScreen ? <AudioPlayer page='upload' type='small-releasealbumplayer' audioSrc={process.env.REACT_APP_BASEURL + 'song' + a.song.song_file} /> : <AudioPlayer page='upload' type='releasealbumplayer' audioSrc={process.env.REACT_APP_BASEURL + 'song' + a.song.song_file} />}
                                </Box>}
                              {a.song.song_status == "draft" ? <Box>
                                <Tooltip title={data.song_status == "In Review" ? releaseStatusReview : data.song_status == "Released" ? releaseStatusReleased : "Add Song Meta"} arrow>
                                  <span >
                                    <button className='mx-1 py-1 px-3 fs14 cursorPointer addMetabutton'
                                      style={disabledAudioAction || albumDisabledStatus ? { backgroundColor: '#ffff', color: '#9e9e9e', cursor: 'not-allowed' } : {}}
                                      disabled={disabledAudioAction == true || albumDisabledStatus ? true : false} onClick={() => AddSongMeta(a.song.song_id)}>ADD META</button>
                                  </span>
                                </Tooltip>
                              </Box> :
                                <Box className=''>
                                  <Tooltip title={data.song_status == "In Review" ? releaseStatusReview : data.song_status == "Released" ? releaseStatusReleased : "Edit Song Meta"} arrow>
                                    <span >
                                      <button className='mx-1 py-1 px-3 fs14 cursorPointer addMetabutton'
                                        disabled={disabledAudioAction || albumDisabledStatus || (a.song.song_status == "Released" || a.song.song_status == "Ready for Release") ? true : false}
                                        style={disabledAudioAction || albumDisabledStatus || (a.song.song_status == "Released" || a.song.song_status == "Ready for Release") ? { backgroundColor: '#ffff', color: '#9e9e9e', cursor: 'not-allowed' } : {}}
                                        onClick={() => AddSongMeta(a.song.song_id)}>EDIT META</button></span>
                                  </Tooltip>
                                </Box>
                              }
                              {/* {a.song.song_status == "Ready for Release" && <Button onClick={(e) => pitchComponent(e, a.song)} variant="text" sx={{ color: '#ED3237' }}>Pitch To Playlist</Button>} */}
                              <IconButton
                                aria-label="more"
                                aria-controls="dropdown-menu"
                                aria-haspopup="true"
                                onClick={(e) => handleClick(e, a)}
                                disabled={disabledAudioAction == true ? true : false}
                              >
                                <MoreVertIcon sx={{ color: "#ED3237" }} />
                              </IconButton>
                              <Menu
                                id="dropdown-menu"
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleActionClose}
                              >
                                <MenuItem disabled={albumDisabledStatus} onClick={() => removeSongFromPlayList()} >
                                  <Tooltip title="Delete Song" arrow>
                                    <DeleteIcon className='mx-1 cursorPointer' sx={{ color: "#ED3237" }} />
                                  </Tooltip>
                                  Delete
                                </MenuItem>
                                <MenuItem onClick={() => ViewSongMeta()}><Tooltip title="Preview Song" arrow>
                                  <VisibilityIcon className='mx-1 cursorPointer' sx={{ color: "#ED3237" }} />
                                </Tooltip>
                                  Preview
                                </MenuItem>
                                <MenuItem disabled={albumDisabledStatus} >
                                  <input
                                    key={0}
                                    type="file"
                                    name="myfile"
                                    multiple
                                    accept=".wav"
                                    onChange={(e) => handleFileInputChange(e, audioId)}
                                    ref={AudiofileInputRef}
                                    className='fileDragDrop'
                                  />
                                  <Tooltip title="Replace Audio" arrow>
                                    <RefreshIcon className='mx-1 cursorPointer' sx={{ color: "#ED3237" }} />
                                  </Tooltip>
                                  Replace Audio
                                </MenuItem>
                                <MenuItem onClick={() => downloadAudio(a.song)} disabled={downloadProgress != 0}><Tooltip title="Download Audio" arrow>
                                  <DownloadIcon className='mx-1 cursorPointer' sx={{ color: "#ED3237" }} />
                                </Tooltip>
                                  Download Audio
                                </MenuItem>
                              </Menu>
                            </Box>
                          </Box>
                        </Card>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext> :
          <Box className=' mt-4'>
            <Skeleton animation="wave" height={70} />
            <Skeleton animation="wave" height={70} />
            <Skeleton animation="wave" height={70} />
          </Box>}


        <Box className="form-group col-md-12 mt-5 " style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Tooltip title={data.song_status == "In Review" ? releaseStatusReview : data.song_status == "Released" ? releaseStatusReleased : ""} arrow>
            <span className=''>
              <button onClick={() => setActive(active + 1)} className='nxt_actionbutton mx-3 py-1 px-4 fs14' >NEXT</button>
           </span>
          </Tooltip>
        </Box>
      </Box>
      {/*  to show and hide the snackbar messages */}
      <SnackbarContainer />
    </Box>
  )
}

export default UploadAlbumFile