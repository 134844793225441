import React from 'react';
import { Modal, Box, Typography, Divider, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getDateAsPerFormat } from '../../../_helpers/reusablefunctions/dateSetter';

const PayoutDetailsModal = ({payoutAmount, historyDetailRow, open, handleClose }) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'white',
                    borderRadius: 3,
                    boxShadow: 24,
                    p: 3,
                    width: 400,
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6" fontWeight="bold">
                        Payout ID: <span style={{ color: '#369204' }}>{historyDetailRow?.reference_id || ''}</span>
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Divider sx={{ my: 2 }} />

                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography fontWeight="bold">Payout</Typography>
                        <Typography>{historyDetailRow?.amount_paid || ''}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontWeight="bold">Requested on</Typography>
                        <Typography>{historyDetailRow?.requested_date ? getDateAsPerFormat(historyDetailRow?.requested_date, 'DD Mon, YYYY') : '--'}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography fontWeight="bold">Paid on</Typography>
                        <Typography>{historyDetailRow?.paid_on ? getDateAsPerFormat(historyDetailRow?.paid_on, 'DD Mon, YYYY') : '--'}</Typography>
                    </Grid>

                    <Grid item xs={6}>
                        <Typography fontWeight="bold">Payout type</Typography>
                        <Typography>Bank Transfer</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography fontWeight="bold">Status</Typography>
                        <Typography sx={{ color: '#00C853' }}>{historyDetailRow?.status || ''}</Typography>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default PayoutDetailsModal;
