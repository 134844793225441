import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Check from '@mui/icons-material/Check';
import { Card, CardContent } from '@mui/material';
import PayeeDetail from './PayeeDetail';
import BankDetail from './BankDetail';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { handleInputChange, isFieldsValid } from './helperComponent';
import ConfirmationModal from './ConfirmationModal';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchRoyaltyDetail } from '../../../redux';
import { useDispatch } from 'react-redux';
import { setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../../redux';
import ApiService from '../../../_services/ApiService';
import { formSnackbar } from '../../../_constants/snackbar.constant';

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 50,
  height: 50,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));
function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  const numbers = {
    1: 1,
    2: 2,
  };
  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? (
        <Check />
      ) : (
        <div>{numbers[String(props.icon)]}</div>
      )}
    </ColorlibStepIconRoot>

  );
}
const steps = ['Address', 'Payment Method'];

export default function Payoutdetails() {
  const warningRef = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [activeStep, setActiveStep] = React.useState(0);
  const [bankDataBkp, setBankDataBkp] = useState();
  const [Payeeid, setPayeeid] = React.useState(null)
  const [payoutData, setPayoutData] = useState({
    type: '',
    pan_num: '',
    gst_num: '',
    phone_number: '',
    contact_email: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    company: '',
    address: '',
    city: '',
    zipcode: '',
    country: '',
    // ---------bank info state -----------
    bank_country: '',
    status: '',
    bank_pincode: '',
    bank_city: '',
    branch_name: '',
    bank_address: '',
    bank_name: '',
    swift: '',
    bank_phone_number: '',
    ifsc_code: '',
    account_number: '',
    account_holder_name: '',
    account_type: '',
    payment_currency: 'INR',
    payment_method: 'Bank Transfer',
  })
  const [payoutDataErrors, setPayoutDataErrors] = useState({
    first_name: false,
    first_nameErr: false,
    pan_num: false,
    pan_numErr: false,
    gst_num: false,
    gst_numErr: false,
    address: false,
    addressErr: false,
    last_name: false,
    last_nameErr: false,
    swift: false,
    swiftErr: false,
    city: false,
    // cityErr: false,
    company: false,
    companyErr: false,
    email: false,
    emailErr: false,
    bank_number: false,
    bank_numberErr: false,
    ifsc: false,
    ifscErr: false,
    account_holder_name: false,
    account_holder_nameErr: false,
    bank_name: false,
    bank_nameErr: false,
    branch_name: false,
    branch_nameErr: false,
    bank_city: false,
    bank_cityErr: false,
    bank_address: false,
    bank_addressErr: false,

  });
  const userData = useSelector((state) => state.userData.userData);
  const [nextBtnDisable, setNextBtnDisable] = useState(true)
  const location = useLocation();
  const editDetail = location?.state

  const messageConfirmation = {
    head: 'You are all set. Payments will be made per your selections.',
    message: 'If you want to review your information, press the back button to review previous steps. If you wish to edit any details, click the edit button on the appropriate form.After editing please proceed through all the steps again until this final confirmation.'
  }
  useEffect(function () {
    if (userData.studio_id) {
      dispatch(fetchRoyaltyDetail("countryList", {}, '', `COUNTRY`))
    }
  }, [userData, dispatch]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      validationHandler(prevActiveStep + 1)
      return (prevActiveStep + 1)
    });

  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      validationHandler(prevActiveStep - 1)
      return (prevActiveStep - 1)
    });
  };

  const warningHandler = () => {
    warningRef.current.handleClose();
    savePayoutDetails() //call the submit
  }
  const handleFinalSave = () => {
    warningRef.current.handleOpen()
  }
  const handleNextClick = (step) => {
    //to request when button is enabled and clicked on next
    if (activeStep !== 1) {
      savePayoutDetails()
    }
    steps.length - 1 === activeStep ? handleFinalSave() : handleNext();
  }

  // ------------------ validation handler start ----------

  //To handle the validatio on input fields
  useEffect(() => {
    validationHandler(activeStep);
  }, [payoutData])

  const validationHandler = (step) => {
    if (step === 0) {
      if (
        (payoutData?.type === '' || payoutData?.type?.length < 1)
        // || (payoutData?.gst_num === '' || payoutData?.gst_num?.length < 1)
        || (payoutData?.pan_num === '' || payoutData?.pan_num?.length < 1)
        || (payoutData?.first_name === '' || payoutData?.first_name?.length < 1)
        || (payoutData?.address === '' || payoutData?.address?.length < 1)
        || (payoutData?.city === '' || payoutData?.city?.length < 1)
        || (payoutData?.zipcode === '' || payoutData?.zipcode?.length < 1)
        || (payoutData?.country === '' || payoutData?.country?.length < 1)
      )
        setNextBtnDisable(true)
      else {
        if (isFieldsValid(payoutDataErrors) === true) {
          if (payoutData.type == 'Company') {
            if ((payoutData?.company == '' || payoutData?.company?.length < 1)) {
              setNextBtnDisable(true)
            } else {
              setNextBtnDisable(false)
            }
          } else {
            setNextBtnDisable(false)
          }
        } else {
          if (payoutData.type == 'Individual') {
            setNextBtnDisable(false)
          } else {
            setNextBtnDisable(true)
          }
        }
      }
    }
    if (step === 1) {
      if ((payoutData?.account_holder_name === '' || payoutData?.account_holder_name?.length < 1)
        || (payoutData?.account_number === '' || payoutData?.account_number?.length < 1)
        || (payoutData?.ifsc_code === '' || payoutData?.ifsc_code?.length < 1)
        || (payoutData?.bank_name === '' || payoutData?.bank_name?.length < 1)
        || (payoutData?.bank_address === '' || payoutData?.bank_address?.length < 1)
        || (payoutData?.branch_name === '' || payoutData?.branch_name?.length < 1)
        || (payoutData?.bank_city === '' || payoutData?.bank_city?.length < 1)
        || (payoutData?.account_type === '' || payoutData?.account_type?.length < 1)
        || (payoutData?.bank_country === '' || payoutData?.bank_country?.length < 1)
      )
        setNextBtnDisable(true)
      else {
        if (isFieldsValid(payoutDataErrors) === true) {
          setNextBtnDisable(false)
        } else {
          setNextBtnDisable(true)
        }
      }
    }
  }

  useEffect(() => {
    if (editDetail?.page === 'edit') {
      const { id, type, gst_num, pan_num, phone_number, contact_email, first_name, middle_name, last_name, company, address, city, zipcode, country, bank_pincode, bank_city, branch_name, bank_address, bank_name, swift, bank_phone_number, ifsc_code, account_number, account_holder_name, account_type, status
      } = editDetail?.data;

      setPayeeid(id);
      const statusValue = status ? "True" : "False";

      const payoutData = {
        type,
        gst_num,
        pan_num,
        phone_number,
        contact_email,
        first_name,
        company,
        middle_name,
        last_name,
        address,
        city,
        zipcode,
        country,
        bank_country: country,
        status: statusValue,
        bank_pincode,
        bank_city,
        branch_name,
        bank_address,
        bank_name,
        swift,
        bank_phone_number,
        ifsc_code,
        account_number,
        account_holder_name,
        account_type,
        payment_currency: 'INR',
        payment_method: 'Bank Transfer'
      };

      setPayoutData(payoutData);
      setBankDataBkp(payoutData);
    }
  }, [editDetail?.page, editDetail?.data]);


  function savePayoutDetails() {
    // -------------Api calling-------------
    if (JSON.stringify(payoutData) === JSON.stringify(bankDataBkp)) {
      if (activeStep === 1) {
        navigate('/viewdetails')
        // handleReset()
      }
      return;
    }
    dispatch(setLoader(true))
    let postData = payoutData;
    if (Payeeid === null || Payeeid === undefined || Payeeid === '') {
      const formData = new FormData();
      for (const key in postData) {
        postData[key] && formData.append(key, postData[key]);
      }
      formData.append('publisher_id', userData.studio_id);
      formData.append('publisher_type', 'studio');
      ApiService.post('payeeData', formData).then(res => {
        let response = res.data;
        if (response.data[0].status === '200' || response.data[0].status === '201') {
          setPayeeid(response.data[0].fields.id)
          setPayoutData(response.data[0].fields)
          setBankDataBkp(response.data[0].fields)
          dispatch(setLoader(false))
          dispatch(setSnackBarVariant('success'))
          dispatch(setSnackBarMessage(formSnackbar.bank.payeeDetail_Success))
          setTimeout(() => dispatch(setSnackBar(false)), 3000)
        }
        formData.append('publisher_id', userData.studio_id);
        formData.append('publisher_type', 'studio');
        // formData.append('status', true)
      }).catch(function (error) {
        console.log(error);
        console.log(error.response);

      });
    } else {
      const formData = new FormData();
      const keys = [
        'type',
        'gst_num',
        'pan_num',
        'phone_number',
        'contact_email',
        'first_name',
        'company',
        'middle_name',
        'last_name',
        'address',
        'city',
        'zipcode',
        'country',
        "branch_name",
        "bank_address",
        "bank_name",
        "swift",
        "bank_phone_number",
        "ifsc_code",
        "account_number",
        "account_holder_name",
        "account_type",
        "payment_currency",
        "payment_method",
        "bank_city",
        "bank_pincode",
        "status"
      ];

      keys.forEach(key => {
        if (payoutData[key]) {
          formData.append(key, payoutData[key]);
        }
      });

      ApiService.put('updatePayeeData', Payeeid, formData, null).then(res => {
        let response = res.data;
        if (res.status === 200 || res.status === 201) {
          dispatch(setLoader(false))
          setPayoutData(response)
          setBankDataBkp(response)
          if (activeStep === 0) {
            dispatch(setSnackBarVariant('success'))
            dispatch(setSnackBarMessage(formSnackbar.bank.bankCreate_Success))
            setTimeout(() => dispatch(setSnackBar(false)), 3000)
          } else {
            navigate('/viewdetails')
            dispatch(setSnackBarVariant('success'))
            dispatch(setSnackBarMessage(formSnackbar.bank.bankCreate_Success))
            setTimeout(() => dispatch(setSnackBar(false)), 3000)
          }
        }

      }).catch(function (error) {
        console.log(error);
        console.log(error.response);

      });
    }

  }

  const getSteps = (activeStep) => {
    switch (activeStep) {
      case 0:
        {
          return <>
            <PayeeDetail payoutData={payoutData} setPayoutData={setPayoutData} payoutDataErrors={payoutDataErrors} handleInputChange={(event) => handleInputChange(event, payoutData, setPayoutData, setPayoutDataErrors, payoutDataErrors)} />
          </>
        }
      case 1:
        {
          return <>
            <BankDetail payoutData={payoutData} setPayoutData={setPayoutData} payoutDataErrors={payoutDataErrors} handleInputChange={(event) => handleInputChange(event, payoutData, setPayoutData, setPayoutDataErrors, payoutDataErrors)} />
          </>
        }
    }
  }

  const backroute = () => {
    navigate('/viewdetails')
  }

  return (
    <Box className='px-0 px-sm-0' sx={{ width: '100%' }}>
      <Button className="icononly-btn w-100 d-flex align-items-center justify-content-start gap-3 text-body" onClick={backroute} ><ArrowBackIcon />{'Payout Method'}</Button>

      <Stepper className='py-3' activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel StepIconComponent={ColorlibStepIcon}  {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      <React.Fragment>
        <Card sx={{ minWidth: 275 }} className='mt-3 mb-5'>
          <CardContent>
            {activeStep === steps.length ? (
              <React.Fragment>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getSteps(activeStep)}
              </React.Fragment>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button className='gn-actionbtn outlined shadow-sm rounded-pill px-4'
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Back
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button
                disabled={nextBtnDisable}
                className='gn-actionbtn shadow-sm rounded-pill text-white px-4' sx={{ backgroundColor: '#E41116 !important' }} onClick={handleNextClick}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </React.Fragment>
      <ConfirmationModal confirmHandler={warningHandler} ref={warningRef} messageConfirmation={messageConfirmation} />
    </Box>
  );
}