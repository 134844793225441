import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch, useSelector } from 'react-redux';
import { Button, useMediaQuery } from '@mui/material';
import { fetchSongReleaseList, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
import imgPlaceHolder from '../../img/default image.png'
import { getDateAsPerFormat, getTime } from '../../_helpers/reusablefunctions/dateSetter';
import { getSongStatusBgColor } from '../../_helpers/reusablefunctions/getColor';
import AudioPlayer from '../../components/reusableComponents/customAudioPlayer';
import { getSingleSongDetails } from '../../_services/submitAsong/getSingleSong.service';
import { formSnackbar } from '../../_constants/snackbar.constant';
import DeleteModal from '../../components/reusableComponents/DeleteModal';
import { deleteSongRequest } from '../../_services/submitAsong/deleteSong.service';

const SongDetails = ({ cardData }) => {
  const smScreen = useMediaQuery('(max-width:768px)')
  const lgScreen = useMediaQuery('(max-width:1280px)')
  const history = useNavigate();
  const dispatch = useDispatch();
  const { songId, albumId } = useParams();
  const songDetails = useSelector(state => state.releaseSongList.releaseSongList.results)
  const userData = useSelector(state => state.userData.userData)
  const ref = React.useRef()
  const [songInfo, setSongInfo] = React.useState([])
  const [showFullLyrics, setShowFullLyrics] = useState(false);
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const toggleReadMore = () => {
    setShowFullLyrics(!showFullLyrics);
  };

  const truncatedLyrics =
    songInfo && songInfo.song_lyrics
      ? songInfo.song_lyrics.substring(0, 500)
      : '';

  const displayedLyrics =
    showFullLyrics && songInfo && songInfo.song_lyrics
      ? songInfo.song_lyrics
      : truncatedLyrics;

  function deleteSongHandler(songId) {
    dispatch(setLoader(true))
    deleteSongRequest(songId)
      .then(function (response) {
        if (response.status === 204 || response.status === 200) {
          dispatch(setLoader(false))
          dispatch(setSnackBar(true));
          dispatch(setSnackBarVariant('success'));
          dispatch(setSnackBarMessage(formSnackbar.submitAsong.SongDeleteSuccess));
          setTimeout(() => dispatch(setSnackBar(false)), 3000)
          history('/releases')
        }
        else {
          dispatch(setLoader(false))
          dispatch(setSnackBar(true));
          dispatch(setSnackBarVariant('error'));
          dispatch(setSnackBarMessage(response.errors.errorMsg));
          setTimeout(() => dispatch(setSnackBar(false)), 3000)
          history('/releases')
        }
      })
      .catch(function (error) {
        dispatch(setLoader(false))
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
        return error.response;
      })
  }
  function getSongDetails(userId, song_id) {
    dispatch(setLoader(true))
    getSingleSongDetails({ 'userId': userId, 'song_id': song_id }, 'GET_SPECIFIC_DISPLAY').then(function (response) {
      dispatch(setLoader(false))
      if (response.status === 200) {
        setSongInfo(response.data[0])
        //setArray(response.data)
      }
      else {
        dispatch(setSnackBar(true));
        dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
        dispatch(setSnackBarVariant('error'));
        setTimeout(() => dispatch(setSnackBar(false)), 3000)
      }
    }).catch(function (error) {
      dispatch(setSnackBar(true));
      dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg));
      dispatch(setSnackBarVariant('error'));
      setTimeout(() => dispatch(setSnackBar(false)), 3000)
      return error.response;
    })
  }

  React.useEffect(() => {
    if (userData.studio_id) {
      if (songId) {
        getSongDetails(userData.studio_id, songId)
      }
    }
  }, [userData.studio_id])

  //to handle the back navigation
  const handleRouteBack = () => {
    history('/addrelease/' + albumId)
  }

  const reapplyHandler = (songId, albumId) => {
    history("/addreleasesong/" + songId + "/" + albumId)
  }
  if (!songInfo) {
    return <h1>Loading ...</h1>;
  }
  return (
    <>
      {songInfo && Object.keys(songInfo).length > 0 &&
        <div >
          <div>
            <button className="icononly-btn" onClick={handleRouteBack}><ArrowBackIcon /> Back</button>
          </div>
          <div className='mt-4'>
            <div className={`d-flex flex-wrap justify-content-between `}>
              {/* column1 */}
              <div className="verifyCol1 p-4 shadow bg-white rounded w-100">
                {/* row 1 */}
                <div >
                  <div className='align-items-end d-flex gap-3 h-100 w-100'>
                    {/* <img src={songInfo && songInfo.cover_image ? process.env.REACT_APP_SONGAPIURL_MEDIA + "/media/" + songInfo.cover_image : imgPlaceHolder} className="w-25 rounded" alt="Not found" /> */}

                    <div className="value-pair w-100">
                      <div style={{ backgroundColor: getSongStatusBgColor(songInfo.song_status) }} className='status text-light'>{songInfo.song_status.slice(0, 1).toUpperCase() + songInfo.song_status.slice(1)}</div>
                      <div className='my-3' style={{ opacity: songInfo.song_file === null ? '0.5' : '1', pointerEvents: songInfo.song_file === null ? 'none' : 'auto' }}>
                        <AudioPlayer type='small' audioSrc={process.env.REACT_APP_BASEURL + 'song/media/' + songInfo.song_file} />
                      </div>
                    </div>
                  </div>

                </div>
                <div className='d-flex align-items-center'>
                  <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                </div>
                <div className='d-flex align-items-center '>
                  <span className="fs-6 fw-bold">Basic Information</span>
                </div>
                <div className={'row mx-0 mt-2'}>
                  <div className='col-md-4 p-0'>
                    <div className="value-pair fs-09"><strong>Song Name: </strong>
                      <span>{songInfo && songInfo.song_name != null && songInfo.song_name}</span>
                    </div>
                    <div className="value-pair fs-09"><strong>Genre: </strong><span>{songInfo.genre}</span></div>
                    <div className="value-pair fs-09"><strong>Mood: </strong><span>{songInfo.mood}</span></div>

                  </div>
                  <div className='col-md-4 p-0'>
                    <div className="value-pair fs-09"><strong>Title Version: </strong>
                      <span>{songInfo && songInfo.title_version !== null ? truncateText(songInfo.title_version, 20) : '--'}</span>
                    </div>
                    <div className="value-pair fs-09"><strong>Instrumental: </strong><span>{songInfo.instrumental ? 'Yes' : 'No'}</span></div>
                    <div className="value-pair fs-09"><strong>Explicit Content: </strong><span>{songInfo.explicit_content ? 'Yes' : 'No'}</span></div>
                  </div>
                  <div className='col-md-4 p-0'>
                    <div className="value-pair fs-09"><strong>Album Name: </strong>
                      <span>{songInfo && songInfo.album.length !== null && truncateText(songInfo.album[0].album_name, 20)}</span>
                    </div>
                    <div className="value-pair fs-09"><strong>Sub-Genre: </strong><span>{songInfo.sub_genre}</span></div>
                  </div>
                  <div className='col-md-12 p-0'>
                    <div className="value-pair fs-09"><strong>Description: </strong><span>{songInfo.song_description ? songInfo.song_description : '--'}</span></div>
                    <div className="value-pair fs-09"><strong>Song Lyrics: </strong>   <span className={showFullLyrics ? 'full-text' : 'truncated-text'}>
                      {displayedLyrics ? displayedLyrics : '--'}
                    </span>
                      {songInfo.song_lyrics && songInfo.song_lyrics.length > 500 && (
                        <span className="active p-2 fw-bold cursorPointer text-decoration-underline" onClick={toggleReadMore}>
                          {showFullLyrics ? 'Show Less' : 'Read More'}
                        </span>
                      )}</div>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                </div>
                <div className='d-flex align-items-center '>
                  <span className="fs-6 fw-bold">Song Credit</span>
                </div>
                <div className='row mx-0 mt-2'>
                  <div className='col-md-4 p-0'>
                    <div className="value-pair fs-09"><strong>Primary Artist: </strong><span>{songInfo.primary_artists.length ? songInfo.primary_artists.map(artist => artist.artist_name).join(', ') : '--'}</span></div>
                    <div className="value-pair fs-09"><strong>Composer: </strong><span>{songInfo.composers.length ? songInfo.composers.map(artist => artist.artist_name).join(', ') : '--'}</span></div>
                    <div className="value-pair fs-09"><strong>Producer: </strong><span>{songInfo.producers.length ? songInfo.producers.map(artist => artist.artist_name).join(', ') : '--'}</span></div>

                  </div>
                  <div className='col-md-4 p-0'>
                    <div className="value-pair fs-09"><strong>Featuring Artist: </strong><span>{songInfo.featuring_artists.length ? songInfo.featuring_artists.map(artist => artist.artist_name).join(', ') : '--'}</span></div>
                    <div className="value-pair fs-09"><strong>Lyricist: </strong><span>{songInfo.lyricists.length ? songInfo.lyricists.map(artist => artist.artist_name).join(', ') : '--'}</span></div>
                    <div className="value-pair fs-09"><strong>Remixer: </strong><span>{songInfo.remixers.length ? songInfo.remixers.map(artist => artist.artist_name).join(', ') : '--'}</span></div>
                  </div>
                  <div className='col-md-4 p-0'>
                    <div className="value-pair fs-09"><strong>Actor: </strong><span>{songInfo.actors.length ? songInfo.actors.map(artist => artist.artist_name).join(', ') : '--'}</span></div>
                   {console.debug("aaaaaaaaaaa",songInfo)}
                    <div className="value-pair fs-09"><strong>Singer: </strong><span>{songInfo.singers.length ? songInfo.singers.map(artist => artist.artist_name).join(', ') : '--'}</span></div>
                {/* <div className="value-pair fs-09"><strong>Release Time: </strong><span>{songInfo.release_time ? getTime(songInfo.release_time) : '--'}</span></div>
                    <div className="value-pair fs-09"><strong>Release Date: </strong><span>{songInfo && songInfo.release_date ? getDateAsPerFormat(songInfo.release_date, "DD Mon, YYYY") : '--'}</span></div> */}
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <hr style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                </div>
                <div className='d-flex align-items-center '>
                  <span className="fs-6 fw-bold">Release Information</span>
                </div>
                <div className='row mx-0 mt-2'>
                  <div className='col-md-6 p-0'>
                    <div className="value-pair fs-09"><strong>Has it been previously released?: </strong><span>{songInfo.release_history === 'False' || songInfo.release_history === false ? 'No' : 'Yes'}</span></div>
                    <div className="value-pair fs-09"><strong>CallerTune Preview/Clip Start Time: </strong><span>{songInfo.crbt_cuts_duration_for_clip1 ? getTime(songInfo.crbt_cuts_duration_for_clip1) : '--'}</span></div>
                    <div className="value-pair fs-09"><strong>What’s the origin of the track? : </strong><span>{songInfo.copyright_type === 'False' || songInfo.copyright_type === false ? 'Public Domain' : 'Original Song'}</span></div>
                    {/* <div className="value-pair fs-09"><strong>Release Time: </strong><span>{songInfo.release_time ? getTime(songInfo.release_time) : '--'}</span></div>
                    <div className="value-pair fs-09"><strong>Release Date: </strong><span>{songInfo.release_date == '' || songInfo.release_date == null ? '--':getDateAsPerFormat(songInfo.release_date, "DD Mon, YYYY")}</span></div> */}
                    <div className="value-pair fs-09"><strong>UPC Code: </strong><span className='text-secondary'> {songInfo.upc_code ? songInfo.upc_code : '--'}</span></div>
                    <div className="value-pair fs-09"><strong>ISRC Code: </strong><span className='text-secondary'>{songInfo.isrc_code ? songInfo.isrc_code : '--'}</span></div>
                  </div>
                  <div className='col-md-6 p-0'>
                    <div className="value-pair fs-09"><strong>Previous Release Date: </strong><span>{(songInfo.previous_release_date == null || songInfo.previous_release_date == '') ? '--' : getDateAsPerFormat(songInfo.previous_release_date, "DD Mon, YYYY")}</span></div>
                    {/* <div className="value-pair fs-09"><strong>CallerTune Preview-2/Clip Start Time: </strong><span>{songInfo.crbt_cuts_duration_for_clip2 ? getTime(songInfo.crbt_cuts_duration_for_clip2) : '--'}</span></div> */}
                    <div className="value-pair fs-09"><strong>© Copyright Year:</strong>
                      <span>{(songInfo?.copyright_c?.copyright_year?.copyright_year == null || songInfo?.copyright_c?.copyright_year?.copyright_year == '')? "--" : songInfo?.copyright_c?.copyright_year?.copyright_year + ' Year'}</span></div>
                    <div className="value-pair fs-09"><strong>© Copyright:</strong>
                      <span>{(songInfo?.copyright_c?.copyright_name == null || songInfo?.copyright_c?.copyright_name == '')?"--":songInfo?.copyright_c?.copyright_name}</span></div>
                    <div className="value-pair fs-09"><strong>Ⓟ Publishing Year:</strong>
                      <span>{(songInfo?.copyright_p?.copyright_year?.copyright_year == null || songInfo?.copyright_p?.copyright_year?.copyright_year == '')? "--":songInfo?.copyright_p?.copyright_year?.copyright_year + ' Year'}</span></div>
                    <div className="value-pair fs-09"><strong>Ⓟ Copyright:</strong>
                      <span>{(songInfo?.copyright_p?.copyright_name == null || songInfo?.copyright_p?.copyright_name == '' ? "--":songInfo?.copyright_p?.copyright_name)}</span></div>

                  </div>
                </div>

                {/* <div className='d-flex justify-content-end'>
                  {songInfo.is_locked ? '' : songInfo.song_status === 'draft' && <div className='mt-4'><button className='gn-actionbtn outlined dark me-3' onClick={() => ref.current.handleOpen()}>Delete</button></div>}
                  {songInfo.is_locked ? '' : songInfo.song_status === 'draft' || songInfo.song_status === 'Rejected' && <div className='mt-4'><button className='gn-actionbtn' onClick={() => reapplyHandler(songInfo.song_id, albumId)}>Edit</button></div>}
                </div> */}
              </div>
            </div >
            {
              songInfo.song_status.toLowerCase() === 'rejected' && (
                <div className={`d-flex flex-wrap justify-content-between ${smScreen ? 'px-0' : 'px-3'} mt-4`}>
                  {/* column1 */}
                  <div className="verifyCol1 p-4 shadow bg-white rounded" style={{ visibility: 'hidden', display: `${lgScreen ? 'none' : 'block'}` }}></div>
                  {/* space */}
                  <div style={{ width: '20px', height: '20px', display: `${lgScreen ? 'none' : 'block'}` }}></div>
                  {/* column2 */}
                  <div className="verifyCol2 p-4 shadow bg-white rounded">
                    <div className='d-flex align-items-center'>
                      <span className="fs-6 text-secondary">Remark</span>
                      <hr className='ms-2' style={{ flexGrow: '1', border: '1px solid rgba(0, 0, 0, 0.12)' }} />
                    </div>
                    <div className='d-flex justify-content-between flex-wrap'>
                      <div style={{ backgroundColor: '#e411160d' }} className="p-4 rounded">
                        <p>{songInfo.comment ? songInfo.comment : '--'}</p>
                      </div>
                      {/* <div className='mt-4'><button className='gn-actionbtn' onClick={reapplyHandler}>Edit</button></div> */}
                    </div>
                  </div>
                </div>
              )
            }

          </div >
        </div >
      }
      <DeleteModal cardId={songInfo && songInfo.song_id} deleteHandler={deleteSongHandler} ref={ref} />
    </>
  )
}

export default SongDetails