import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Search from './Search';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TableComponent from './Tablecomponent';
import { monthDynamic } from './TabConstData';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchRoyaltyDetail } from '../../redux';
import { useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import { formatIndianNumber, flattenPartnerDetail } from '../../_services/formateRevenueData';

export default function Months({ hideTotal }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const studioData = useSelector((state) => state.userData.userData);
    const monthData = useSelector((state) => state.royaltyDataReducer.monthData);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const total_count = monthData?.total_count
    const publisherId = studioData.studio_id;
    const partener_detail = { partner_type: 'studio', partner_id: publisherId, share_type: 'contract_partner' }

    const redirectDetails = (data) => {
        navigate('/details', { state: { data: monthDynamic, value: [data], pageCall: 'singleMonthDetails', name: data?.month } });
    }

    useEffect(function () {
        const flattenedPartnerDetail = flattenPartnerDetail(partener_detail);
        if (studioData.studio_id) {
            dispatch(fetchRoyaltyDetail("monthData", { offset: page, limit: rowsPerPage, ...flattenedPartnerDetail }, studioData.studio_id, `MONTH`))
        }
    }, [studioData, dispatch, page, rowsPerPage]);


    const rows = (monthData.total_data?.length ? monthData.total_data : []) || [];

    const monthHead = [
        // { id: 'month', numeric: false, field: 'month', label: 'Months' },
        {
            id: 'month', label: 'Months', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className="d-flex align-items-center">{row?.month +' '+ row?.year}</Box>
                );
            }
        },
        { id: 'total_stream_count', numeric: true, field: 'total_stream_count', label: 'Streams' },
        { id: 'release_download_count', numeric: true, field: 'release_download_count', label: 'Release Downloads' },
        { id: 'track_download_count', numeric: true, field: 'track_download_count', label: 'Track Downloads' },
        { id: 'video_download_count', numeric: true, field: 'video_download_count', label: 'Video Downloads' },
        {
            id: 'total_stream_earning', label: 'Earnings', headerName: '', flex: 1, template: (row) => {
                const totalDownloads = row.total_stream_earning + row.total_release_download_earning + row.total_track_download_earning + row.total_video_download_earning;
                return (
                    <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(totalDownloads)}</Box>
                );
            }
        },
        // {
        //     id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
        //         return (
        //             <Box className='text-decoration-none viewDetailgradient cursorPointer' onClick={() => redirectDetails(row)} >View Details</Box>
        //         )
        //     }
        // },
    ];

    const monthFooter = [
        { id: 'name', numeric: false, label: 'Totals' },
        { id: 'streams', numeric: true, label: formatIndianNumber(monthData.overall_total_streams) },
        { id: 'releasedownloads', numeric: true, label: formatIndianNumber(monthData.overall_release_downloads) },
        { id: 'trackdownloads', numeric: true, label: formatIndianNumber(monthData.overall_track_downloads) },
        { id: 'videodownloads', numeric: true, label: formatIndianNumber(monthData.overall_video_downloads) },
        {
            id: 'earnings', numeric: true, label: <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />
                {formatIndianNumber(monthData.overall_streams_earning + monthData.overall_release_downloads_earnings + monthData.overall_track_downloads_earnings + monthData.overall_video_downloads_earnings)}</Box>
        },
        // { id: 'action', numeric: false, label: '' }
    ];

    return (
        <Paper sx={{ width: '100%', mb: 2, mt: 1 }}>
            <Search headValue={'Months'} />
            <TableComponent columns={monthHead} data={rows} headFooter={monthFooter} hideTotal={hideTotal} total_count={total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        </Paper>
    );
}
