import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Search from './Search';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import TableComponent from './Tablecomponent';
import { storeDynamic } from './TabConstData';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchRoyaltyDetail } from '../../redux';
import { useSelector } from 'react-redux';
import { formatIndianNumber, getMonthValue, flattenPartnerDetail } from '../../_services/formateRevenueData';
import { Paper } from '@mui/material';

export default function Stores({ hideTotal }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const studioData = useSelector((state) => state.userData.userData);
    const singlestoreData = useSelector((state) => state.royaltyDataReducer.singlestoreData);
    const storeData = useSelector((state) => state.royaltyDataReducer.storeData);
    const location = useLocation();
    const isDetailsPage = location.pathname === '/details';
    const tabDynamic = location?.state
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const total_count = storeData?.total_count
    const publisherId = studioData.studio_id;
    const partener_detail = { partner_type: 'studio', partner_id: publisherId, share_type: 'contract_partner' }


    const redirectDetails = (data) => {
        navigate('/details', { state: { data: storeDynamic, value: [data], pageCall: 'singlestoreData', name: data?.platform, id: data?.platform_id } });
    }

    useEffect(function () {
        const flattenedPartnerDetail = flattenPartnerDetail(partener_detail);
        if (isDetailsPage) {
            // if (singlestoreData?.counts_per_platform?.length == 0 || singlestoreData.length == 0) {
            const monthName = tabDynamic?.name;
            let songId = tabDynamic?.id ? tabDynamic?.id : getMonthValue(monthName)
            dispatch(fetchRoyaltyDetail(tabDynamic.pageCall, { offset: page, limit: rowsPerPage, publisher_type: 'studio', publisher_id: publisherId, ...flattenedPartnerDetail }, songId + '/platform', `SINGLE_STORE`))
            // }
        }
        else {
            if (studioData.studio_id) {
                // if (storeData.length == 0) {
                dispatch(fetchRoyaltyDetail("storeData", { offset: page, limit: rowsPerPage, ...flattenedPartnerDetail }, studioData.studio_id, `STORE`))
                // }
            }
        }
    }, [studioData, dispatch, page, rowsPerPage]);

    const rows = (isDetailsPage ? singlestoreData?.counts_per_platform : storeData.total_data?.length ? storeData.total_data : []) || [];

    const columns = [
        {
            id: 'platform', label: 'Store', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className="d-flex align-items-center gap-2"><Box sx={{ height: 35, width: 35 }}><img className='w-100 h-100 rounded-pill ' style={{ objectFit: 'cover' }} src={process.env.REACT_APP_BASEURL + 'song' + row.platform_logo} alt='platform-image' /></Box>{row.platform}</Box>
                );
            }
        },
        { id: 'total_stream_count', field: "total_stream_count", label: 'Streams' },
        { id: 'release_download_count', field: "release_download_count", label: 'Release Downloads' },
        { id: 'track_download_count', field: "track_download_count", label: 'Track Downloads' },
        { id: 'video_download_count', field: "video_download_count", label: 'Video Downloads' },
        {
            id: 'total_stream_earning', label: 'Earnings', headerName: '', flex: 1, template: (row) => {
                const totalDownloads = row.total_stream_earning + row.total_release_download_earning + row.total_track_download_earning + row.total_video_download_earning;
                return (
                    <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(totalDownloads)}</Box>
                );
            }
        },
        // {
        //     id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
        //         return (
        //             <Box className='text-decoration-none viewDetailgradient cursorPointer' onClick={() => redirectDetails(row)} >View Details</Box>
        //         )
        //     }
        // },
    ];

    const headFooter = [
        { id: 'name', numeric: false, label: 'Totals' },
        { id: 'streams', numeric: false, label: storeData.length != 0 ? formatIndianNumber(storeData.overall_total_streams) : 0 },
        { id: 'releasedownloads', numeric: true, label: storeData.length != 0 ? formatIndianNumber(storeData.overall_release_downloads) : 0 },
        { id: 'trackdownloads', numeric: true, label: storeData.length != 0 ? formatIndianNumber(storeData.overall_track_downloads) : 0 },
        { id: 'videodownloads', numeric: true, label: storeData.length != 0 ? formatIndianNumber(storeData.overall_video_downloads) : 0 },
        { id: 'earnings', numeric: false, label: <Box className="d-flex align-items-center"><CurrencyRupeeIcon sx={{ fontSize: '15px' }} />{formatIndianNumber(storeData.overall_release_downloads_earnings + storeData.overall_track_downloads_earnings + storeData.overall_streams_earning + storeData.overall_video_downloads_earnings)}</Box> },
        // { id: 'action', numeric: false, label: '' }
    ];


    return (
        <Paper sx={{ width: '100%', mb: 2, mt: 1 }}>
            <Search headValue={'Stores'} />
            <TableComponent columns={columns} data={rows} headFooter={headFooter} hideTotal={hideTotal} total_count={total_count} page={page} setPage={setPage} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} />
        </Paper>
    );
}
