import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Grid, Typography, Avatar, List, ListItem, ListItemAvatar, ListItemText, Skeleton, Tooltip } from '@mui/material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import ApiService from '../../../_services/ApiService';
import { setLoader } from '../../../redux';
import { getDateAsPerFormat } from '../../../_helpers/reusablefunctions/dateSetter';
import PayoutDetails from '../modals/PayoutDetails';
import TableComponent from '../Tablecomponent';
import PayoutModal from './PayoutModal';

import payoutmethod from '../../../img/payoutmethod.svg';
import currentbalance from '../../../img/currentbalance.svg';
import BankIcon from "../../../img/bank-building.svg";
import rupee from '../../../img/rupee.svg';
import rupee1 from '../../../img/rupee1.svg';
import PayoutDetailsModal from './PayoutDetailsModal';

export default function Payout() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [payoutAmount, setPayoutAmount] = useState(null);
    const [historyDetailRow, setHistoryDetailRow] = useState(null);
    const [openPayout, setOpenPayout] = useState(false);
    const [openDeteails, setOpenDetails] = useState(false);
    const [amount, setAmount] = useState('');
    const [historyData, setHistoryData] = useState([]);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [total_count, setTotal_count] = useState(0);
    const [bankDetail, setBankDetail] = useState([])
    const userData = useSelector((state) => state.userData.userData);

    useEffect(() => {
        if (userData?.studio_id) {
            ApiService.get('viewPayeeData', {}, userData.studio_id).then(res => {
                if (res.status == 200 || res.status == '201') {

                    setBankDetail(res.data.results)
                }
            }).catch(function (error) {
                console.log(error);
                console.log(error.response);
            });
        }
    }, [userData])

    const getPayoutDetails = async () => {
        try {
            const [revenueLedgerRes, payoutStatusRes] = await Promise.all([
                ApiService.get('revenue_ledger', { publisher_id: userData.studio_id, publisher_type: "studio" }),
                ApiService.get('payout_status', { publisher_id: userData.studio_id, publisher_type: "studio", limit: limit, offset: offset })
            ]);
            setTotal_count(payoutStatusRes.data?.total_count);
            setPayoutAmount(revenueLedgerRes.data);
            setHistoryData(payoutStatusRes.data?.results);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            // dispatch(setLoader(false));
        }
    };

    useEffect(() => {
        if (userData.studio_id) {
            // dispatch(setLoader(true));
            getPayoutDetails();
        }
    }, [userData, limit, offset]);

    function clearData() {
        setAmount('')
        setOpenPayout(false);
    }

    const submitRequest = (e) => {
        const formData = new FormData();
        formData.append('publisher_id', userData.studio_id);
        formData.append('publisher_type', 'studio');
        formData.append('amount_paid', amount);

        dispatch(setLoader(true));

        ApiService.post('revenue_ledger', formData)
            .then(() => getPayoutDetails())
            .finally(() => { clearData(); dispatch(setLoader(false)) });
    };

    const color = { color: 'rgba(76, 78, 100, 0.87)' };

    const renderBalanceSection = () => (
        <Box className="d-flex">
            <Box className='align-items-center justify-content-evenly w-50 d-flex flex-column'>
                {payoutAmount ? (
                    <>
                        <Box className="d-flex align-items-center justify-content-center gap-2">
                            <img src={currentbalance} alt='currentbalance' />
                            <Box>
                                <Typography style={color} variant='h4'>
                                    <CurrencyRupeeIcon fontSize='large' />
                                    {payoutAmount?.net_revenue || '00'}
                                </Typography>
                                <Typography style={{ ...color, fontSize: 14 }} variant='span'>
                                    Your Current Balance
                                </Typography>
                            </Box>
                        </Box>
                        <Tooltip
                            title={bankDetail?.some(d => d?.status) ? "" : "Add bank details/Active bank"}
                            disableInteractive arrow
                        >
                            <span> {/* Wrap Button in a span to fix tooltip not working on disabled buttons */}
                                <Button
                                    variant="contained"
                                    className="gn-actionbtn text-white rounded-pill px-4"
                                    sx={{ backgroundColor: "rgba(76, 78, 100, 0.68)" }}
                                    onClick={() => setOpenPayout(true)}
                                    disabled={!bankDetail?.some(d => d?.status)}
                                >
                                    Withdraw
                                </Button>
                            </span>
                        </Tooltip>


                    </>
                ) : (
                    <Skeleton variant="rectangular" width="60%" height={100} />
                )}
            </Box>

            <Divider sx={{ height: '165px !important' }} orientation="vertical" flexItem />

            <Box className="w-50 d-flex flex-column justify-content-center">
                <Box className="d-flex align-items-center gap-2 pb-3 ps-3">
                    <img src={rupee} alt='rupee' />
                    <Box>
                        {payoutAmount ? (<Typography style={color} variant='h6'>
                            <CurrencyRupeeIcon fontSize='small' />
                            {payoutAmount?.available_balance || '00'}
                        </Typography>) : (
                            <Skeleton variant="rectangular" width="100%" height={50} />
                        )}
                        <Typography style={{ ...color, fontSize: 14 }} variant='span'>
                            Available Balance
                        </Typography>
                    </Box>
                </Box>
                <Divider />
                <Box className="d-flex align-items-center gap-2 pt-2 ps-3">
                    <img src={rupee1} alt='rupee' />
                    <Box>
                        {payoutAmount ? (<Typography style={color} variant='h6'>
                            <CurrencyRupeeIcon fontSize='small' />
                            {payoutAmount?.pending_balance || '00'}
                        </Typography>) : (
                            <Skeleton variant="rectangular" width="100%" height={50} />
                        )}
                        <Typography style={{ ...color, fontSize: 14 }} variant='span'>
                            Pending
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

    const columns = [
        { id: 'amount_paid', field: 'amount_paid', label: 'Payout' },
        {
            id: 'payouttype',
            label: 'Payout Type',
            template: () => <Box className="d-flex align-items-center">Bank Transfer</Box>
        },
        {
            id: 'requestedon',
            label: 'Requested On',
            template: (row) => (
                <Box className="d-flex align-items-center">
                    {getDateAsPerFormat(row.requested_date, 'DD Mon, YYYY')}
                </Box>
            )
        },
        {
            id: 'status', label: 'Status', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className=''>
                        <Typography variant='subtitle1' sx={{ color: row?.status == "Paid" ? '#00C853' : '#F14950 ' }}>{row?.status}</Typography>
                    </Box>
                )
            }
        },
        {
            id: 'action', label: 'Action', headerName: '', flex: 1, template: (row) => {
                return (
                    <Box className='text-decoration-none viewDetailgradient cursorPointer' onClick={() => { setOpenDetails(true); setHistoryDetailRow(row) }} >View Details</Box>
                )
            }
        },
    ];

    return (
        <Box>
            <h2>Payout</h2>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md={6}>
                    <Box className="bg-white shadow-sm rounded" minHeight="230px">
                        <Box className='px-3 py-3'>
                            <Typography style={color} variant='h6'>Payouts</Typography>
                        </Box>
                        <Divider />
                        {renderBalanceSection()}
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box className="bg-white shadow-sm rounded" minHeight="230px">
                        <Box className='d-flex justify-content-between align-items-center px-3 py-3'>
                            <Typography style={color} variant='h6'>Payout Methods</Typography>
                            <Button
                                sx={{ backgroundColor: '#E41116 !important' }}
                                onClick={() => navigate('/viewdetails')}
                                className='gn-actionbtn px-2 py-1 shadow-none text-white fw-normal'
                            >
                                View Banks
                            </Button>
                        </Box>
                        <Divider />
                        <Box className="d-flex">
                            <Box className='w-75'>
                                <List>
                                    <ListItem>
                                        <ListItemAvatar>
                                            <Avatar sx={{ height: 55, width: 55 }} className='bg-light border'>
                                                <img className='w-50' src={BankIcon} alt='bankIcon' />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            className='fs-6 fw-bold'
                                            primary={<Typography variant='subtitle2'>Bank Transfer {bankDetail?.length && bankDetail.some(d => d?.status) ? <Typography variant='span' sx={{ fontSize: '10px' }} className='border rounded-pill px-2 py-1 bg-info text-white'> Active</Typography> : ''} </Typography>}
                                        />
                                    </ListItem>
                                </List>
                            </Box>
                            <Box className='w-25'>
                                <img src={payoutmethod} className='w-100' alt='payoutmethod' />
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>

            <Box className="bg-white shadow-sm rounded mt-3 py-4 mb-5">
                <Typography style={{ ...color, paddingBottom: 10, paddingLeft: 10 }} variant='h6'>
                    Payout History
                </Typography>
                <PayoutDetails open={open} handleClickOpen={() => setOpen(true)} handleClose={() => setOpen(false)} />
                <TableComponent columns={columns} data={historyData || []} total_count={total_count}
                    page={offset} setPage={setOffset} rowsPerPage={limit} setRowsPerPage={setLimit}
                    hideTotal />
            </Box>

            <PayoutModal
                payoutAmount={payoutAmount}
                open={openPayout}
                setAmount={setAmount}
                amount={amount}
                handleClose={() => setOpenPayout(false)}
                submitRequest={submitRequest}
                clearData={clearData}
            />

            <PayoutDetailsModal payoutAmount={payoutAmount} historyDetailRow={historyDetailRow} open={openDeteails} handleClose={() => setOpenDetails(false)} />
        </Box>
    );
}
