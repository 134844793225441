
export const trackDynamic = [
    { id: 1, tabActiveId: "1",  tabTitle: 'MUSIC RELEASES', tabType: 'musicrelease', icons: "musicicon", },
    { id: 2, tabActiveId: "2",  tabTitle: 'STORES', tabType: 'stores', icons: "storeicon", },
    { id: 3, tabActiveId: "3",  tabTitle: 'VIDEOS', tabType: 'videos', icons: "videoicon", },
    // { id: 3, tabActiveId: "3", tabTitle: 'MONTHS', tabType: 'months', icons: "monthicon"},
    // { id: 4, tabActiveId: "4",  tabTitle: 'COUNTRIES', tabType: 'country',  icons: "countryicon", }
]

export const storeDynamic = [
    { id: 1, tabActiveId: "1",  tabTitle: 'MUSIC RELEASES', tabType: 'musicrelease', icons: "musicicon", },
    { id: 2, tabActiveId: "2",  tabTitle: 'TRACKS', tabType: 'track', icons: "audioicon", },
    { id: 3, tabActiveId: "3",  tabTitle: 'VIDEOS', tabType: 'videos', icons: "videoicon", },
    // { id: 4, tabActiveId: "4",  tabTitle: 'MONTHS', tabType: 'months', icons: "monthicon"},
    // { id: 5, tabActiveId: "5",  tabTitle: 'COUNTRIES', tabType: 'country', icons: "countryicon", }
]
export const monthDynamic = [
    { id: 1, tabActiveId: "1",  tabTitle: 'MUSIC RELEASES', tabType: 'musicrelease', icons: "musicicon", },
    { id: 2, tabActiveId: "2",  tabTitle: 'TRACKS', tabType: 'track', icons: "audioicon", },
    { id: 3, tabActiveId: "3", tabTitle: 'VIDEOS', tabType: 'videos', icons: "videoicon", },
    { id: 4, tabActiveId: "4",  tabTitle: 'STORES', tabType: 'stores', icons: "storeicon", },
    // { id: 5, tabActiveId: "5",  tabTitle: 'COUNTRIES', tabType: 'country', icons: "countryicon", }
]
export const countryDynamic = [
    { id: 1, tabActiveId: "1",  tabTitle: 'MUSIC RELEASES', tabType: 'musicrelease', icons: "musicicon", },
    { id: 2, tabActiveId: "2",  tabTitle: 'TRACKS', tabType: 'track', icons: "audioicon", },
    { id: 3, tabActiveId: "3",  tabTitle: 'VIDEOS', tabType: 'videos',icons: "videoicon", },
    { id: 4, tabActiveId: "4",  tabTitle: 'STORES', tabType: 'stores', icons: "storeicon", },
    // { id: 5, tabActiveId: "5",  tabTitle: 'MONTHS', tabType: 'months', icons: "monthicon"},
]

export const albumDynamic = [
    { id: 1, tabActiveId: "1",  tabTitle: 'TRACKS', tabType: 'track', icons: "audioicon", },
    { id: 2, tabActiveId: "2", tabTitle: 'VIDEOS', tabType: 'videos',  icons: "videoicon", },
    { id: 3, tabActiveId: "3",  tabTitle: 'STORES', tabType: 'stores', icons: "storeicon",},
    // { id: 4, tabActiveId: "4", tabTitle: 'MONTHS', tabType: 'months', icons: "monthicon"},
    // { id: 5, tabActiveId: "5",  tabTitle: 'COUNTRIES', tabType: 'country', icons: "countryicon", }
]
