import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Add, Close } from '@mui/icons-material';
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, ListItemIcon, ListItemText, MenuItem, OutlinedInput, Select, Skeleton, TextField, Typography, useMediaQuery } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useDispatch } from 'react-redux';
import { fetchReleaseAlbum, setLoader, setSnackBar, setSnackBarMessage, setSnackBarVariant } from '../../redux';
// import { formSnackbar } from '../../../../_services/_constants/snackbar.constant';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getGenre } from '../../_services/profile';
import { regexValidator } from '../../_helpers/reusablefunctions/regexValidator';
import { formSnackbar } from '../../_constants/snackbar.constant';
import { updateAlbum } from '../../_services/album/updateAlbum';
import { createAlbum } from '../../_services/album/createAlbum';
import { useNavigate } from 'react-router-dom';


const style = {
  position: 'absolute',
  // top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  // width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  borderRadius: '8px',
  boxShadow: 24,

};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function CreateAlbumModal({ isOpen, onClose, onCreateAlbum, albumData, artistList, type, genre}) {
  const dispatch = useDispatch()
  const [albumName, setAlbumName] = React.useState(albumData ? albumData.album_name : '');
  const mdScreen = useMediaQuery('(max-width:768px)');
  const xmScreen = useMediaQuery('(max-width:300px)');
  const smScreen= useMediaQuery('(max-width:600px)');
  const [albumNameErr, setAlbumNameErr] = React.useState(null)
  const [albumNameErrMsg, setAlbumNameErrMsg] = React.useState('')
  const userData = useSelector((state) => state.userData.userData);
  const [selectedGenres, setSelectedGenres] = React.useState(albumData ? albumData.genre : null);
  const [disableBtn, setBtnDisable] = React.useState(albumData ? albumData.album_name && albumData.album_name.length > 0 ? false : true : true)
  const history = useNavigate()

  useEffect(() => {
    getGenreList()
  }, [])


  const getGenreList = () => {
      if (albumData && albumData.genre) {
        const matchedGenre = genre.find(item => item.id === albumData.genre);
        if (matchedGenre) {
          setSelectedGenres(matchedGenre.id);
        }
      }

  }

  const handleClose = () => {
    setAlbumName('');
    setSelectedGenres([])
    onClose();
  }

  const inputChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'album_name') {
      setAlbumName(value);
      const charCount = value.length;
      const isValid = regexValidator(value, 'space')
      // Handle minimum length error
      if (charCount < 3) {
        setBtnDisable(true);
        setAlbumNameErr(true);
        setAlbumNameErrMsg('Minimum three characters are required.');
      } else if (charCount > 49) { // Handle maximum length error
        setBtnDisable(true);
        setAlbumNameErr(true);
        setAlbumNameErrMsg('Max limit is 50 characters.');
      } else if(isValid){
        setBtnDisable(true);
        setAlbumNameErr(true);
        setAlbumNameErrMsg('Space is not allowed.');
      } else {
        setBtnDisable(false);
        setAlbumNameErr(false);
        setAlbumNameErrMsg('');
      }
    }
  };


  const handleGenreChange = (event) => {
    setSelectedGenres(event.target.value);
  };

  const handleSubmit = () => {
    // Validate album details, and create the card if valid
    if (albumName && selectedGenres) {
      const selectedGenresId = selectedGenres
      const albumDetails = {
        album_name: albumName,
        genre: selectedGenresId,
        publisher_id: userData.studio_id,
        publisher_type: "studio",
      };
      var formData = new FormData()
      if (type != 'edit') {
        for (const key in albumDetails) {
          if (albumDetails.hasOwnProperty(key) && albumDetails[key] !== null) {

            formData.append(key, albumDetails[key]);
          }
        }
      } else {
        const albumEditDetails = {
          album_name: albumName,
          genre: selectedGenresId,
        };
        for (const key in albumEditDetails) {
          if (albumEditDetails.hasOwnProperty(key) && albumEditDetails[key] !== null) {

            formData.append(key, albumEditDetails[key]);
          }
        }
      }

      // Call the onCreateAlbum function to add the new album
      //call api to create the album
      dispatch(setLoader(true))
      if (type !== 'edit') {
        createAlbum(formData)
          .then(function (response) {
            let r = response?.data?.data[0]
            if (r.status === '200' || r.status === '201') {
              let d = []
              dispatch(fetchReleaseAlbum(userData.studio_id, { limit: 25, offset: 0 }, d, null));
              dispatch(setSnackBar(true))
              dispatch(setSnackBarVariant('success'))
              dispatch(setSnackBarMessage(formSnackbar.album.albumCreate_Success))
              dispatch(setLoader(false))
              history("/addrelease/"+r?.fields.album_id)
              onClose()
              setTimeout(function () {
                dispatch(setSnackBar(false))
              }, 2000)
            }
          })
          .catch(function (error) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
            setTimeout(function () {
              dispatch(setSnackBar(false))
            }, 2000)
          })
      }
      else {
        updateAlbum(albumData.album_id, formData)
          .then(function (response) {
            if (response.status === 200 || response.status === 201) {
              let d = []
              dispatch(fetchReleaseAlbum(userData.studio_id, { limit: 25, offset: 0 }, d, null));
              dispatch(setSnackBar(true))
              dispatch(setSnackBarVariant('success'))
              dispatch(setSnackBarMessage(formSnackbar.album.albumUpdate))
              dispatch(setLoader(false))
              setTimeout(function () {
                dispatch(setSnackBar(false))
              }, 2000)
            }
          })
          .catch(function (error) {
            dispatch(setSnackBar(true))
            dispatch(setSnackBarVariant('error'))
            dispatch(setSnackBarMessage(formSnackbar.errors.errorMsg))
            setTimeout(function () {
              dispatch(setSnackBar(false))
            }, 2000)
          })
      }

      // Close the drawer
      handleClose();
    } else {
      // Handle validation errors or display a message
    }
  };

  return (
    <React.Fragment>
      <Modal
        open={isOpen}
        onClose={onClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, top: xmScreen|| smScreen ? '25%':'50%',  width: xmScreen|| smScreen ?300: 400 }}>
        <div>
        <div className="p-2 d-flex justify-content-between align-items-center shadow-sm" style={{ backgroundColor: 'rgb(248 248 248)' }}>
          <div style={{ marginLeft: '16px' }}><h4>{type !== 'edit' ? 'Add Release' : 'Edit Release'}</h4></div>
          <div style={{ position: 'relative', top: '-5px' }}>
            <button title="Close" className="icononly-btn" onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>
        </div>
        <div className='p-4'>
          <div className='mb-3 mt-3'>
            <TextField
              size='small'
              name="album_name"
              label="Release Name*"
              variant="outlined"
              className="w-100 rounded mt-2"
              value={albumName ? albumName :''}
              onChange={inputChangeHandler}
              error={albumNameErr}
              helperText={albumNameErr ? albumNameErrMsg : null}
            />
          </div>
          <div className='mb-3'>
            <FormControl fullWidth size='small'>
              <InputLabel id="demo-multiple-checkbox-label">Select Genre</InputLabel>
              <Select
                labelId="demo-single-checkbox-label"
                id="demo-single-checkbox"
                value={selectedGenres ? selectedGenres : null}
                onChange={handleGenreChange}
                input={<OutlinedInput label="Select Genre" />}
                renderValue={(selected) => genre.length != 0 && genre.find(genre => genre.id === selected).genre}
                MenuProps={MenuProps}
              >
                {genre && genre.length > 0 && genre.map((g) => (
                  <MenuItem key={g.id} value={g.id}>
                    <ListItemText primary={g.genre} />
                  </MenuItem>
                ))}
              </Select>

            </FormControl>
          </div>
          <div className='mt-4 d-flex justify-content-end '>
            <button className='gn-actionbtn' disabled={(selectedGenres != null &&albumName!='')?false:true } onClick={handleSubmit} >Submit</button>
          </div>
        </div>
      </div>
          

        </Box>
      </Modal>
    </React.Fragment>
  );
}
export default CreateAlbumModal