import React, { useState } from 'react';
import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    IconButton
} from '@mui/material';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import CloseIcon from '@mui/icons-material/Close';

const PayoutModal = ({ open, payoutAmount, handleClose, submitRequest, amount, setAmount, clearData }) => {

    const handleAmountChange = (e) => {
        const value = parseFloat(e.target.value) || ''; // Ensure numeric value
        setAmount(value);
    };

    return (
        <Modal open={open}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    // p: 4,
                    borderRadius: 2
                }}
            >
                <Box display="flex" justifyContent="end" alignItems="center">
                    <IconButton onClick={clearData}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box px={4} pb={4}>
                    <Typography variant="h6" gutterBottom>
                        Your Available Balance
                    </Typography>
                    <Typography variant="h4" sx={{ fontWeight: 'bold' }}>
                        <CurrencyRupeeIcon fontSize={'large'} />
                        {payoutAmount?.available_balance || '00'}
                    </Typography>

                    <Typography sx={{ mt: 3 }}>Request a payout</Typography>

                    <TextField
                        label="Rs"
                        type="number"
                        variant="outlined"
                        fullWidth
                        value={amount}
                        onChange={handleAmountChange}
                        inputProps={{ min: 0 }}
                        sx={{ my: 2 }}
                    />
                    <Button
                        variant="contained"
                        className='gn-actionbtn'
                        fullWidth
                        disabled={amount > payoutAmount?.available_balance || amount < 100}
                        onClick={submitRequest}
                    >
                        Withdraw
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default PayoutModal;
